/* eslint-disable unicorn/prefer-module */
/* eslint-disable @typescript-eslint/no-var-requires */

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["__sentryRewritesTunnelPath__"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"842f83924e8ceb99e86ef45d60754c7c89ec156a"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import * as Sentry from '@sentry/nextjs'

const Environment = process.env.NOVELAI_ENVIRONMENT ?? 'debug'
const Distribution =
    process.env.NEXT_PUBLIC_COMMITHASH?.slice(0, 7) + '-' + (process.env.NOVELAI_ENVIRONMENT ?? '')
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 0.2,
    environment: Environment,
    release: process.env.NEXT_PUBLIC_COMMITHASH,
    dist: Distribution,
    enabled: !!Environment && Environment !== 'debug',
    autoSessionTracking: false,
})
