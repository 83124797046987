import { PlatformImageData as StaticImageData } from '../compatibility/platformtypes'
import { modelSupportsUserModules } from '../data/ai/model'
import { colorModels, DefaultModel, normalizeModel, TextGenerationModel } from '../data/request/model'
import { PrefixOptions } from '../data/story/defaultprefixes'
import { StoryContainer } from '../data/story/storycontainer'
import { DefaultPrefixOption, NoModule } from '../data/story/storysettings'
import { Environment } from '../globals/constants'
import { EncoderType, getModelEncoderType } from '../tokenizer/enums'
import { containsJapaneseText, isDefaultModule } from './util'

import Calliope from '../assets/images/calliope.webp'
import Sigurd from '../assets/images/sigurd.webp'
import Genji from '../assets/images/genji.webp'
import Snek from '../assets/images/snek.webp'
import Euterpe from '../assets/images/euterpe.webp'
import Clio from '../assets/images/clio.webp'
import Krak from '../assets/images/krak.webp'
import Kayra from '../assets/images/kayra.webp'
import Erato from '../assets/images/erato.webp'

import KayraBust from '../assets/kit/busts/small_kayra.webp'
import KrakeBust from '../assets/kit/busts/small_krake.webp'
import ClioBust from '../assets/kit/busts/small_clio.webp'
import EuterpeBust from '../assets/kit/busts/small_euterpe.webp'
import SnekBust from '../assets/kit/busts/small_snek.webp'
import GenjiBust from '../assets/kit/busts/small_genji.webp'
import SigurdBust from '../assets/kit/busts/small_sigurd.webp'
import CalliopeBust from '../assets/kit/busts/small_calliope.webp'
import EratoBust from '../assets/kit/busts/small_erato.webp'

import Blue from '../assets/images/blue.webp'
import Red from '../assets/images/red.webp'
import Green from '../assets/images/green.webp'
import Purple from '../assets/images/purple.webp'
import Pink from '../assets/images/pink.webp'
import Yellow from '../assets/images/yellow.webp'
import White from '../assets/images/white.webp'
import Black from '../assets/images/black.webp'
import { Localization } from '../hooks/useLocalization'

export interface ModelData {
    label: string
    description: string
    img: StaticImageData
    bust: StaticImageData
    str: TextGenerationModel
    opusOnly?: boolean
    loreGen?: boolean
    hidden?: boolean
    tlstring: string
}

const MODELS = [
    {
        label: 'Blue',
        description: '',
        img: Blue,
        bust: Blue,
        str: TextGenerationModel.blue,
        loreGen: true,
        staging: true,
        tlstring: 'blue',
    },
    {
        label: 'Red',
        description: '',
        img: Red,
        bust: Red,
        str: TextGenerationModel.red,
        loreGen: true,
        staging: true,
        tlstring: 'red',
    },
    {
        label: 'Green',
        description: '',
        img: Green,
        bust: Green,
        str: TextGenerationModel.green,
        loreGen: true,
        staging: true,
        tlstring: 'green',
    },
    {
        label: 'Purple',
        description: '',
        img: Purple,
        bust: Purple,
        str: TextGenerationModel.purple,
        loreGen: true,
        staging: true,
        tlstring: 'purple',
    },
    {
        label: 'Pink',
        description: '',
        img: Pink,
        bust: Pink,
        str: TextGenerationModel.pink,
        loreGen: true,
        staging: true,
        tlstring: 'pink',
    },
    {
        label: 'Yellow',
        description: '',
        img: Yellow,
        bust: Yellow,
        str: TextGenerationModel.yellow,
        loreGen: true,
        staging: true,
        tlstring: 'yellow',
    },
    {
        label: 'White',
        description: '',
        img: White,
        bust: White,
        str: TextGenerationModel.white,
        loreGen: true,
        staging: true,
        tlstring: 'white',
    },
    {
        label: 'Black',
        description: '',
        img: Black,
        bust: Black,
        str: TextGenerationModel.black,
        loreGen: true,
        staging: true,
        tlstring: 'black',
    },
    {
        label: 'Llama 3 Erato',
        description: 'Our largest and most formidable, built with Meta Llama 3 70B.',
        img: Erato,
        bust: EratoBust,
        str: TextGenerationModel.erato,
        opusOnly: true,
        tlstring: 'erato',
    },
    {
        label: 'Kayra',
        description: 'A fierce contender, now second best, crafted from the ground up.',
        img: Kayra,
        bust: KayraBust,
        str: TextGenerationModel.kayra,
        loreGen: true,
        tlstring: 'kayra',
    },
    {
        label: 'Clio',
        description: 'Our veteran powerhouse, still fast and reliable.',
        img: Clio,
        bust: ClioBust,
        str: TextGenerationModel.clio,
        loreGen: true,
        tlstring: 'clio',
    },
    {
        label: 'Euterpe',
        description: 'A legacy model - use is no longer recommended.',
        img: Euterpe,
        bust: EuterpeBust,
        str: TextGenerationModel.euterpev2,
        loreGen: true,
        tlstring: 'euterpe',
    },
    {
        label: 'Krake',
        description: 'Previously powerful, now legacy model - use is no longer recommended.',
        img: Krak,
        bust: KrakeBust,
        str: TextGenerationModel.krakev2,
        opusOnly: true,
        loreGen: true,
        tlstring: 'krake',
    },
    {
        label: 'Sigurd',
        description: 'A legacy model - use is no longer recommended.',
        img: Sigurd,
        bust: SigurdBust,
        str: TextGenerationModel.j6bv4,
        loreGen: true,
        tlstring: 'sigurd',
    },
    {
        label: 'Genji',
        description: 'A legacy Japanese language model - use is no longer recommended.',
        img: Genji,
        bust: GenjiBust,
        str: TextGenerationModel.genjijp6bv2,
        tlstring: 'genji',
    },
    {
        label: 'Snek',
        description: 'A legacy python code model - use is no longer recommended.',
        img: Snek,
        bust: SnekBust,
        str: TextGenerationModel.genjipython6b,
        opusOnly: true,
        tlstring: 'snek',
    },
    {
        label: 'Calliope',
        description: 'A legacy model - use is no longer recommended.',
        img: Calliope,
        bust: CalliopeBust,
        str: TextGenerationModel.neo2b,
        staging: true,
        tlstring: 'calliope',
    },
    {
        label: 'Cassandra',
        description: "OccultSage's experimental model.",
        img: Calliope,
        bust: CalliopeBust,
        str: TextGenerationModel.cassandra,
        staging: true,
        tlstring: 'cassandra',
    },
    {
        label: 'HypeBot',
        description: "You shouldn't see this.",
        img: Purple,
        bust: Purple,
        str: TextGenerationModel.commentBot,
        hidden: true,
        tlstring: 'hypebot',
    },
    {
        label: 'Infill',
        description: "You shouldn't see this.",
        img: Purple,
        bust: Purple,
        str: TextGenerationModel.infill,
        hidden: true,
        tlstring: 'infill',
    },
]

export const InnateTAModels = new Set([
    normalizeModel(TextGenerationModel.clio),
    normalizeModel(TextGenerationModel.kayra),
    normalizeModel(TextGenerationModel.erato),
    TextGenerationModel.blue,
    TextGenerationModel.red,
    TextGenerationModel.green,
    TextGenerationModel.purple,
    TextGenerationModel.pink,
    TextGenerationModel.yellow,
    TextGenerationModel.white,
    TextGenerationModel.black,
])

export function getAvailiableModels(
    opus: boolean,
    localization?: Localization,
    hidden?: boolean
): ModelData[] {
    const filtered = MODELS.filter((model) => !model.opusOnly || opus)
        .filter((model) => Environment !== 'production' || !model.staging)
        .filter((model) => !model.hidden || hidden)
        // Replace labels and descriptions with localized strings
        .map((model) => {
            if (!localization) return model
            return {
                ...model,
                label: (localization.models as any)[model.tlstring].name ?? model.label,
                description: (localization.models as any)[model.tlstring].description ?? model.description,
            }
        })
    return filtered
}

export function getLoregenModels(
    opus: boolean,
    allowKrake: boolean,
    localization?: Localization
): ModelData[] {
    return getAvailiableModels(opus, localization).filter(
        (m) => m.loreGen && (allowKrake || m.str !== TextGenerationModel.krakev1)
    )
}
export function getModuleTrainingModels(opus: boolean, localization?: Localization): ModelData[] {
    return getAvailiableModels(opus, localization).filter((m) => modelSupportsUserModules(m.str))
}

export function modelsCompatible(
    modelA: TextGenerationModel | undefined,
    modelB: TextGenerationModel | undefined
): boolean {
    if (!modelA || !modelB) return false
    return normalizeModel(modelA) === normalizeModel(modelB)
}

export function modelsHaveSamePresets(
    modelA: TextGenerationModel | undefined,
    modelB: TextGenerationModel | undefined
): boolean {
    if (modelA && modelB && colorModels.has(modelA) && colorModels.has(modelB)) {
        return true
    }
    return modelsCompatible(modelA, modelB)
}

export function modelName(model: TextGenerationModel, localization: Localization): string {
    const normModel = normalizeModel(model)
    return (localization.models as any)[MODELS.find((m) => m.str === normModel)?.tlstring ?? 'unknown'].name
}

export function prefixModel(id: string): TextGenerationModel {
    return id.split(':')[0] as TextGenerationModel
}

export function prefixIsDefault(id: string): boolean {
    return !id.includes(':') && PrefixOptions.has(id)
}

export function isStoryCompatibleWithPreferredModel(
    preferred: TextGenerationModel,
    story: StoryContainer
): boolean {
    return (
        !!preferred &&
        // if no prefix used
        (!story.content.settings.prefix ||
            // or if default prefix is used
            prefixIsDefault(story.content.settings.prefix) ||
            // or if models are compatible
            modelsCompatible(prefixModel(story.content.settings.prefix ?? DefaultPrefixOption), preferred))
    )
}

export enum PreambleConditions {
    True,
    False,
    OnSettingEnabled,
    OnLowContext,
    OnEmptyContext,
    OnModule,
    OnAdventureModule,
    OnEmptyPreamble,
    OnDefaultModule,
    OnNerdstashTokenizer,
    OnLlama3Tokenizer,
    OnAdventureMode,
    OnStoryIsAtributeHeader,
    OnContextHasATTG,
    OnContextHasYear,
    OnContextHasS,
    OnContainsJapaneseText,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const nand = (a: boolean, b: boolean) => !(a && b)
const and = (a: boolean, b: boolean) => a && b
const or = (a: boolean, b: boolean) => a || b
const xor = (a: boolean, b: boolean) => a !== b

type ConditionOperator = (a: boolean, b: boolean) => boolean

type PreambleOperation = (
    preamble: string,
    tokens?: number[],
    module?: string
) => [string, undefined | number[]]

// NOTE: newTokens is only used for cases where an impossible token sequence is used as the preamble.
// Preambles of this type should be avoided. and genji should possibly be changed to used a different
// preamble so this logic can be removed.
const set = (newText: string, newTokens?: number[]): PreambleOperation => {
    return () => [newText, newTokens]
}

const add = (newText: string, start?: boolean): PreambleOperation => {
    return (preamble: string) => [start ? newText + preamble : preamble + newText, undefined]
}

const modulePreambleMap: Record<string, string> = {
    generator_recipes: '<|endoftext|>----\n',
    special_chat: '<|endoftext|>[ Style: chat ]\n',
    style_williamshakespeare: '<|endoftext|>ACT I\nSCENE I.',
    theme_19thcenturyromance: '<|endoftext|>[ Tags: 1800s; Genre: romance ]\n[ Prologue ]\n',
    theme_actionarcheology: '<|endoftext|>[ Genre: action, adventure ]\n[ Prologue ]\n',
    theme_ai: '<|endoftext|>[ Tags: tech, AIs ]\n[ Prologue ]\n',
    theme_airships: '<|endoftext|>[ Tags: Victorian, steam tech; Genre: steampunk ]\n[ Prologue ]\n',
    theme_ancientchina: '<|endoftext|>[ Tags: ancient history; Genre: historical fiction ]\n[ Prologue ]\n',
    theme_ancientgreek: '<|endoftext|>[ Genre: historical fiction ]\n[ Prologue ]\n',
    theme_animalfiction: '<|endoftext|>[ Tags: dark; Genre: animal fiction ]\n[ Prologue ]\n',
    theme_anthropomorphicanimals: '<|endoftext|>[ Tags: furry ]\n[ Prologue ]\n',
    "theme_children's":
        "<|endoftext|>[ Tags: young protagonist; Genre: children's, adventure ]\n[ Prologue ]\n",
    theme_childrens: "<|endoftext|>[ Genre: children's ]\n[ Prologue ]\n",
    theme_comedicfantasy: '<|endoftext|>[ Genre: humor, fantasy ]\n[ Prologue ]\n',
    theme_cyberpunk: '<|endoftext|>[ Tags: near future, dystopia; Genre: cyberpunk ]\n[ Prologue ]\n',
    theme_darkfantasy: '<|endoftext|>[ Tags: dark; Genre: dark fantasy ]\n[ Prologue ]\n',
    theme_dragons: '<|endoftext|>[ Tags: dragons, scalie ]\n[ Prologue ]\n',
    theme_gaming: '<|endoftext|>[ Tags: contemporary, gaming ]\n[ Prologue ]\n',
    theme_generalfantasy: '<|endoftext|>[ Genre: adventure, fantasy ]\n[ Prologue ]\n',
    theme_goldenagescifi: '<|endoftext|>[ Genre: science fiction, pulp ]\n[ Prologue ]\n',
    theme_hardsf: '<|endoftext|>[ Tags: tech; Genre: hard SF ]\n[ Prologue ]\n',
    theme_horror: '<|endoftext|>[ Tags: dark; Genre: horror ]\n[ Prologue ]\n',
    theme_humor: '<|endoftext|>[ Genre: humor ]\n[ Prologue ]\n',
    theme_libraries:
        '<|endoftext|>[ Tags: books about books, metafiction; Genre: magical realism ]\n[ Prologue ]\n',
    theme_litrpg: '<|endoftext|>[ Genre: LitRPG ]\n',
    theme_magicacademy: '<|endoftext|>[ Tags: magic school, magic ]\n[ Prologue ]\n',
    theme_mars: '<|endoftext|>[ Tags: Mars; Genre: science fiction ]\n[ Prologue ]\n',
    theme_militaryscifi: '<|endoftext|>[ Tags: military; Genre: science fiction ]\n[ Prologue ]\n',
    theme_mystery: '<|endoftext|>[ Genre: mystery, thriller ]\n[ Prologue ]\n',
    theme_noir: '<|endoftext|>[ Genre: noir ]\n[ Prologue ]\n',
    theme_philosophy: '<|endoftext|>[ Tags: essay; Genre: philosophy ]\n',
    theme_pirates: '<|endoftext|>[ Tags: naval, 1600s; Genre: action, adventure ]\n[ Prologue ]\n',
    theme_postapocalyptic: '<|endoftext|>[ Tags: dark, post-apocalyptic ]\n[ Prologue ]\n',
    theme_spaceopera:
        '<|endoftext|>[ Tags: far future, aliens; Genre: space opera, adventure ]\n[ Prologue ]\n',
    theme_superheroes: '<|endoftext|>[ Genre: superheroes ]\n[ Prologue ]\n',
    theme_textadventure: '<|endoftext|>[ Style: text adventure ]\n',
    theme_urbanfantasy: '<|endoftext|>[ Tags: contemporary; Genre: urban fantasy ]\n[ Prologue ]\n',
    theme_valentines: '<|endoftext|>[ Genre: romance ]\n[ Prologue ]\n',
    theme_vikings: '<|endoftext|>[ Theme: Norse ]\n[ Prologue ]\n',
    theme_weirdwest: '<|endoftext|>[ Genre: weird west, bizarro ]\n[ Prologue ]\n',
    special_proseaugmenter: '<|endoftext|>[ Prologue ]\n',
    theme_india: '[ Tags: ancient history ]\n[ Prologue ]\n',
    theme_romanempire: '[ Genre: historical fiction ]\n[ Prologue ]\n',
    theme_sciencefantasy: '[ Genre: science fiction fantasy ]\n[ Prologue ]\n',
    theme_rats: '<|endoftext|>[ Tags: rats ]\n',
    style_algernonblackwood: '<|endoftext|>[ Author: Algernon Blackwood ]\n[ Prologue ]\n',
    style_arthurconandoyle: '<|endoftext|>[ Author: Arthur Conan Doyle ]\n[ Prologue ]\n',
    style_hplovecraft: '<|endoftext|>[ Author: H. P. Lovecraft; Tags: Lovecraftian ]\n',
    style_edgarallanpoe: '<|endoftext|>[ Author: Edgar Allan Poe ]\n[ Prologue ]\n',
    style_janeausten: '<|endoftext|>[ Author: Jane Austen ]\n[ Prologue ]\n',
    style_shridanlefanu: '<|endoftext|>[ Author: Sheridan Le Fanu ]\n[ Prologue ]\n',
    style_julesverne: '<|endoftext|>[ Author: Jules Verne ]\n[ Prologue ]\n',
}

type PreambleParams = {
    model: TextGenerationModel
    preambleSettingEnabled: boolean
    emptyContext: boolean
    fullContent: boolean
    module: string
    adventureMode: boolean
    storyText: string
    context: string
    preambleDebugDisable: boolean
}

// Accepts params and returns true or false
type PreambleFunctionRule = (params: PreambleParams) => boolean

interface PreambleRule {
    condition: (PreambleConditions | PreambleFunctionRule)[]
    conditionOperators: ConditionOperator[]
    operation: PreambleOperation
    halt?: boolean
}

const ConditionShortcut = {
    adventureCondition: {
        condition: [PreambleConditions.OnAdventureModule, PreambleConditions.OnEmptyContext],
        conditionOperators: [and],
        operation: set('You look around.\n'),
        halt: true,
    },
    settingAndLowOrEmpty: (operation: PreambleOperation) => {
        return {
            condition: [
                PreambleConditions.OnEmptyContext,
                PreambleConditions.OnSettingEnabled,
                PreambleConditions.OnLowContext,
            ],
            conditionOperators: [and, or],
            operation,
        }
    },
    modulePreambleReplacement: {
        // If the preamble is already set, and we are using a module with a defined preamble,
        // then we should replace the preamble with the module preamble.
        condition: [PreambleConditions.OnEmptyPreamble, PreambleConditions.True],
        conditionOperators: [nand],
        operation: (preamble: string, tokens?: number[], module?: string): [string, undefined | number[]] => {
            if (module !== undefined && modulePreambleMap[module]) {
                return [modulePreambleMap[module], undefined]
            }
            return [preamble, tokens]
        },
    },
    contextStartsWith(text: string): PreambleFunctionRule {
        return ({ context }) => context.startsWith(text)
    },
    contextHasLine(text: string, limit: number): PreambleFunctionRule {
        // Check if any line withing the limit matches the text
        return ({ context }) => context.split('\n').slice(0, limit).includes(text)
    },
    contextLineStartsWith(text: string, limit: number): PreambleFunctionRule {
        // Check if any line withing the limit starts with the text
        return ({ context }) =>
            context
                .split('\n')
                .slice(0, limit)
                .some((line) => line.startsWith(text))
    },
}

const FallbackPreambleDefinition: PreambleRule[] = [
    {
        condition: [PreambleConditions.OnEmptyContext],
        conditionOperators: [],
        operation: set('<|endoftext|>'),
    },
    // Use empty preamble if empty context and nerdstash tokenizer
    {
        condition: [PreambleConditions.OnEmptyContext, PreambleConditions.OnNerdstashTokenizer],
        conditionOperators: [and],
        operation: set(''),
    },
    // Use empty preamble if empty context and llama3 tokenizer
    {
        condition: [PreambleConditions.OnEmptyContext, PreambleConditions.OnLlama3Tokenizer],
        conditionOperators: [and],
        operation: set(''),
    },
]

const ColorModelPreambleDefinition: PreambleRule[] = [
    // Default, <|startoftext|>
    {
        condition: [PreambleConditions.True],
        conditionOperators: [],
        operation: set('<|startoftext|>'),
    },
    // If low context, <|reserved_special_token_81|>
    {
        condition: [PreambleConditions.OnLowContext],
        conditionOperators: [],
        operation: set('<|reserved_special_token_81|>'),
    },
    // If empty context and setting enabled add, \n[ S: 5 ]\n[ Style: prose, opening ]
    {
        condition: [PreambleConditions.OnEmptyContext, PreambleConditions.OnSettingEnabled],
        conditionOperators: [and],
        operation: add('\n[ S: 5 ]\n[ Style: prose, opening ]'),
    },
    // If context starts with "<|startoftext|>", no preamble
    {
        condition: [ConditionShortcut.contextStartsWith('<|startoftext|>')],
        conditionOperators: [],
        operation: set(''),
    },
    // If context starts with "====", <|startoftext|>
    {
        condition: [ConditionShortcut.contextStartsWith('====')],
        conditionOperators: [],
        operation: set('<|startoftext|>'),
    },
]

const ColorModelPreambleAltDefinition: PreambleRule[] = [
    // Default, <|startoftext|>
    {
        condition: [PreambleConditions.True],
        conditionOperators: [],
        operation: set('<|startoftext|>'),
    },
    // If low context, <|reserved_special_token_81|>
    {
        condition: [PreambleConditions.OnLowContext],
        conditionOperators: [],
        operation: set('<|reserved_special_token_81|>'),
    },
    // If low context, no attg, no year, and no s, add [ S: 4 ]\n
    {
        condition: [
            PreambleConditions.OnSettingEnabled,
            PreambleConditions.OnLowContext,
            PreambleConditions.True,
            PreambleConditions.OnContextHasATTG,
            PreambleConditions.OnContextHasYear,
            PreambleConditions.OnContextHasS,
        ],
        conditionOperators: [or, or, xor, and, and],
        operation: add('[ S: 4 ]\n'),
    },
    // If empty context and setting enabled add, \n[ S: 4 ]\n[ Style: prose, opening ]\n
    {
        condition: [PreambleConditions.OnEmptyContext, PreambleConditions.OnSettingEnabled],
        conditionOperators: [and],
        operation: set('<|reserved_special_token_81|>[ S: 4 ]\n[ Style: prose, opening ]\n'),
    },
    // If context starts with "<|startoftext|>", no preamble
    {
        condition: [ConditionShortcut.contextStartsWith('<|startoftext|>')],
        conditionOperators: [],
        operation: set(''),
    },
    // If context starts with "====", <|startoftext|>
    {
        condition: [ConditionShortcut.contextStartsWith('====')],
        conditionOperators: [],
        operation: set('<|startoftext|>'),
    },
]

const ColorModelPreambleChatDefinition: PreambleRule[] = [
    // Default, <|startoftext|>
    {
        condition: [PreambleConditions.True],
        conditionOperators: [],
        operation: set('<|startoftext|>'),
    },
    // If low context, <|reserved_special_token_81|>
    {
        condition: [PreambleConditions.OnLowContext],
        conditionOperators: [],
        operation: set('<|endoftext|>'),
    },
]

const ColorModelPreamblBOSDefinition: PreambleRule[] = [
    // Default, <|startoftext|>
    {
        condition: [PreambleConditions.True],
        conditionOperators: [],
        operation: set('<|startoftext|>'),
    },
]

const ColorModelPreamblBOSPlusDefinition: PreambleRule[] = [
    // Default, <|startoftext|>
    {
        condition: [PreambleConditions.True],
        conditionOperators: [],
        operation: set('<|startoftext|>'),
    },
    // If empty context and setting enabled add, \n[ S: 4 ]\n[ Style: opening, prose ]\n
    {
        condition: [PreambleConditions.OnEmptyContext, PreambleConditions.OnSettingEnabled],
        conditionOperators: [and],
        operation: add('[ S: 4 ]\n[ Style: opening, prose ]\n'),
    },
]

const preambleDefinitions: Map<TextGenerationModel, PreambleRule[]> = new Map([
    [
        normalizeModel(TextGenerationModel.euterpev2),
        [
            ConditionShortcut.settingAndLowOrEmpty(set('***\n')),
            ConditionShortcut.modulePreambleReplacement,
            {
                // Newline should be added if either no module, or a default module is used.
                condition: [
                    PreambleConditions.OnDefaultModule,
                    PreambleConditions.OnModule,
                    PreambleConditions.True,
                ],
                conditionOperators: [xor, or],
                operation: add('\n', true),
            },
        ],
    ],
    [
        normalizeModel(TextGenerationModel.krakev2),
        [
            ConditionShortcut.settingAndLowOrEmpty(set('<|endoftext|>\n')),
            // If the context is completely empty, use "<|endoftext|>[ Prologue ]\n" instead.
            {
                condition: [PreambleConditions.OnEmptyContext],
                conditionOperators: [],
                operation: set('<|endoftext|>[ Prologue ]\n'),
            },
            ConditionShortcut.modulePreambleReplacement,
        ],
    ],
    [
        normalizeModel(TextGenerationModel.j6bv4),
        [ConditionShortcut.settingAndLowOrEmpty(set('⁂\n')), ConditionShortcut.modulePreambleReplacement],
    ],
    [normalizeModel(TextGenerationModel.neo2b), [ConditionShortcut.settingAndLowOrEmpty(set('⁂\n'))]],
    [
        normalizeModel(TextGenerationModel.genjijp6bv2),
        [
            {
                condition: [PreambleConditions.OnEmptyContext],
                conditionOperators: [],
                operation: set(']\n\n', [60, 198, 198]),
            },
        ],
    ],
    [
        normalizeModel(TextGenerationModel.clio),
        [
            {
                condition: [PreambleConditions.OnEmptyContext],
                conditionOperators: [],
                operation: set('[ Author: Various ]\n[ Prologue ]\n'),
            },
            {
                // If context is completely empty or premable is on and we are in adventure mode
                condition: [
                    PreambleConditions.OnAdventureMode,
                    PreambleConditions.OnEmptyContext,
                    PreambleConditions.OnSettingEnabled,
                ],
                conditionOperators: [or, and],
                operation: set('[ Style: text adventure, long descriptions ]\n'),
            },
            {
                // If in adventure mode and context is empty
                condition: [PreambleConditions.OnAdventureMode, PreambleConditions.OnEmptyContext],
                conditionOperators: [and],
                operation: set('[ Style: text adventure, opening, evocative, long descriptions ]\n'),
            },
        ],
    ],
    [
        normalizeModel(TextGenerationModel.kayra),
        [
            {
                // If context is completely empty or premable is on and we are in adventure mode
                condition: [
                    PreambleConditions.OnAdventureMode,
                    PreambleConditions.OnEmptyContext,
                    PreambleConditions.OnSettingEnabled,
                ],
                conditionOperators: [or, and],
                operation: set('[ Style: text adventure, long descriptions ]\n'),
            },
            {
                // If in adventure mode or module and context is empty
                condition: [
                    PreambleConditions.OnEmptyContext,
                    PreambleConditions.OnAdventureMode,
                    PreambleConditions.OnAdventureModule,
                ],
                conditionOperators: [or, and],
                operation: set('[ Style: text adventure, opening, evocative, long descriptions ]\n'),
            },
        ],
    ],
    [
        normalizeModel(TextGenerationModel.red),
        ColorModelPreamblBOSDefinition,
        /*
        // Default, <|startoftext|>
        [
            {
                condition: [PreambleConditions.True],
                conditionOperators: [],
                operation: set('<|startoftext|>'),
            },
        ],*/
    ],
    [normalizeModel(TextGenerationModel.blue), ColorModelPreamblBOSDefinition],
    [normalizeModel(TextGenerationModel.green), ColorModelPreambleDefinition],
    [normalizeModel(TextGenerationModel.purple), ColorModelPreambleChatDefinition],
    [normalizeModel(TextGenerationModel.pink), ColorModelPreamblBOSDefinition],
    [normalizeModel(TextGenerationModel.yellow), ColorModelPreamblBOSDefinition],
    [normalizeModel(TextGenerationModel.white), ColorModelPreambleAltDefinition],
    [normalizeModel(TextGenerationModel.black), ColorModelPreamblBOSDefinition],
    [
        normalizeModel(TextGenerationModel.erato),
        [
            // Default, <|startoftext|>
            {
                condition: [PreambleConditions.True],
                conditionOperators: [],
                operation: set('<|startoftext|>'),
            },
            // If low context, <|reserved_special_token_81|>
            {
                condition: [PreambleConditions.OnLowContext],
                conditionOperators: [],
                operation: set('<|reserved_special_token_81|>'),
            },
            // If low context, no attg, no year, and no s, add [ S: 4 ]\n
            {
                condition: [
                    PreambleConditions.OnSettingEnabled,
                    PreambleConditions.OnLowContext,
                    PreambleConditions.True,
                    PreambleConditions.OnContextHasATTG,
                    PreambleConditions.OnContextHasYear,
                    PreambleConditions.OnContextHasS,
                ],
                conditionOperators: [or, or, xor, and, and],
                operation: set('<|reserved_special_token_81|>[ S: 4 ]\n'),
            },
            // If empty context and setting enabled add, \n[ S: 4 ]\n[ Style: prose, opening ]\n
            {
                condition: [PreambleConditions.OnEmptyContext, PreambleConditions.OnSettingEnabled],
                conditionOperators: [and],
                operation: set('<|reserved_special_token_81|>[ S: 4 ]\n[ Style: prose, opening ]\n'),
            },
            // If Japanese text and low context, set, <|reserved_special_token_84|>
            {
                condition: [PreambleConditions.OnContainsJapaneseText, PreambleConditions.OnLowContext],
                conditionOperators: [and],
                operation: set('<|reserved_special_token_84|>'),
            },
            // If Japanese text and not low context, set, <|startoftext|>
            {
                condition: [
                    PreambleConditions.OnContainsJapaneseText,
                    PreambleConditions.OnLowContext,
                    PreambleConditions.True,
                ],
                conditionOperators: [xor, and],
                operation: set('<|startoftext|>'),
            },
            // If Japanese text and empty context and first three lines don't start with "[ジャンル:", "[タグ:", "[タイトル:", or "[あらすじ:"
            // add, <|reserved_special_token_84|>[ジャンル:小説]\n[タグ:連載]\n***\n
            {
                condition: [
                    PreambleConditions.OnContainsJapaneseText,
                    PreambleConditions.True,
                    ConditionShortcut.contextLineStartsWith('[ジャンル:', 3),
                    ConditionShortcut.contextLineStartsWith('[タグ:', 3),
                    ConditionShortcut.contextLineStartsWith('[タイトル:', 3),
                    ConditionShortcut.contextLineStartsWith('[あらすじ:', 3),
                ],
                conditionOperators: [or, or, or, xor, and],
                operation: add('[ジャンル:小説]\n[タグ:連載]\n***\n'),
            },
            // If any of the first three lines of context are "====", <|startoftext|>
            {
                condition: [ConditionShortcut.contextHasLine('====', 3)],
                conditionOperators: [],
                operation: set('<|startoftext|>'),
            },
            // If adventure and preamble on, setting on, set, <|startoftext|>[ Style: text adventure ]\n
            {
                condition: [PreambleConditions.OnAdventureMode, PreambleConditions.OnSettingEnabled],
                conditionOperators: [and],
                operation: set('<|startoftext|>[ Style: text adventure ]\n'),
            },
            // If adventure and preamble on, setting on, and low context set,
            // <|reserved_special_token_81|>[ Style: text adventure ]\n
            {
                condition: [
                    PreambleConditions.OnAdventureMode,
                    PreambleConditions.OnSettingEnabled,
                    PreambleConditions.OnLowContext,
                ],
                conditionOperators: [and, and],
                operation: set('<|reserved_special_token_81|>[ Style: text adventure ]\n'),
            },
            // If adventure and empty context, <|reserved_special_token_81|>[ Style: text adventure ]\n
            {
                condition: [PreambleConditions.OnAdventureMode, PreambleConditions.OnEmptyContext],
                conditionOperators: [and],
                operation: set('<|reserved_special_token_81|>[ Style: text adventure ]\n'),
            },
            // If context starts with "<|startoftext|>", no preamble
            {
                condition: [ConditionShortcut.contextStartsWith('<|startoftext|>')],
                conditionOperators: [],
                operation: set(''),
            },
            // If context starts with "<|reserved_special_token_81|>", no preamble
            {
                condition: [ConditionShortcut.contextStartsWith('<|reserved_special_token_81|>')],
                conditionOperators: [],
                operation: set(''),
            },
            // If context starts with "<|reserved_special_token_84|>", no preamble
            {
                condition: [ConditionShortcut.contextStartsWith('<|reserved_special_token_84|>')],
                conditionOperators: [],
                operation: set(''),
            },
        ],
    ],
])

export function calcPreamble({
    model,
    preambleSettingEnabled,
    emptyContext,
    fullContent,
    module,
    adventureMode,
    storyText,
    context,
    preambleDebugDisable,
}: PreambleParams): { str: string; exactTokens?: number[] } {
    if (preambleDebugDisable) {
        return { str: '', exactTokens: undefined }
    }

    let definition = preambleDefinitions.get(normalizeModel(model))
    if (!definition) {
        definition = FallbackPreambleDefinition
    }

    let preambleString = ''
    let preambleTokens: undefined | number[]

    for (const rule of definition) {
        const conditionsStack = rule.condition.map((c) => {
            switch (c) {
                case PreambleConditions.True: {
                    return true
                }
                case PreambleConditions.False: {
                    return false
                }
                case PreambleConditions.OnSettingEnabled: {
                    return preambleSettingEnabled
                }
                case PreambleConditions.OnLowContext: {
                    return !fullContent
                }
                case PreambleConditions.OnEmptyContext: {
                    return emptyContext
                }
                case PreambleConditions.OnModule: {
                    return (module ?? NoModule) !== NoModule
                }
                case PreambleConditions.OnAdventureModule: {
                    return module === 'theme_textadventure'
                }
                case PreambleConditions.OnEmptyPreamble: {
                    return preambleString === ''
                }
                case PreambleConditions.OnDefaultModule: {
                    if (module === undefined || module === NoModule) {
                        return false
                    }
                    return isDefaultModule(module)
                }
                case PreambleConditions.OnNerdstashTokenizer: {
                    return (
                        getModelEncoderType(model) === EncoderType.Nerdstash ||
                        getModelEncoderType(model) === EncoderType.NerdstashV2
                    )
                }
                case PreambleConditions.OnLlama3Tokenizer: {
                    return getModelEncoderType(model) === EncoderType.Llama3
                }
                case PreambleConditions.OnAdventureMode: {
                    return adventureMode
                }
                case PreambleConditions.OnStoryIsAtributeHeader: {
                    return storyText.trim() === '----'
                }
                case PreambleConditions.OnContextHasATTG: {
                    // Any line of context starts with [ and is then followed by Author:, Title:, Tags:, or Genre:
                    const bracketLines = context.split(/\r?\n/).filter((line) => line.startsWith('['))
                    return bracketLines.some((line) => {
                        return (
                            line.includes('Author:') ||
                            line.includes('Title:') ||
                            line.includes('Tags:') ||
                            line.includes('Genre:')
                        )
                    })
                }
                case PreambleConditions.OnContextHasYear: {
                    // Any line of context starts with [ Y:
                    const bracketLines = context.split(/\r?\n/).filter((line) => line.startsWith('[ Y:'))
                    return bracketLines.length > 0
                }
                case PreambleConditions.OnContextHasS: {
                    // Any line of context starts with [ S:
                    const bracketLines = context.split(/\r?\n/).filter((line) => line.startsWith('[ S:'))
                    return bracketLines.length > 0
                }
                case PreambleConditions.OnContainsJapaneseText: {
                    return containsJapaneseText(context)
                }
                default: {
                    if (typeof c === 'function') {
                        return c({
                            model,
                            preambleSettingEnabled,
                            emptyContext,
                            fullContent,
                            module,
                            adventureMode,
                            storyText,
                            context,
                            preambleDebugDisable,
                        })
                    }
                }
            }
        })
        const operators = rule.conditionOperators
        // of condition stack length isn't +1 of operator stack length configuration is invalid, throw error
        if (conditionsStack.length - 1 !== operators.length) {
            throw new Error(
                `Invalid preamble rule configuration. ${conditionsStack.length} conditions and ${operators.length} operators`
            )
        }

        // evaluate condition stack as postfix notation
        for (const operator of operators) {
            const a = conditionsStack.pop()
            const b = conditionsStack.pop()
            if (a === undefined || b === undefined) {
                throw new Error(
                    `Invalid preamble rule configuration. ${a === undefined ? 'a' : 'b'} is undefined`
                )
            }
            conditionsStack.push(operator(a, b))
        }
        if (conditionsStack.pop()) {
            const [newPreamble, newTokens] = rule.operation(preambleString, preambleTokens, module)

            preambleString = newPreamble
            preambleTokens = newTokens
            if (rule.halt) {
                break
            }
        }
    }
    return { str: preambleString, exactTokens: preambleTokens }
}
