import { Session, SettingsModalOpen } from '../globals/state'
import { BanStatus } from '../data/user/user'
import { useLocalization } from '../hooks/useLocalization'
import Modal, { ModalType } from './modals/modal'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { CloseButton } from './modals/common'
import { SubtleButton } from '../styles/ui/button'
import { LinkIcon } from '../styles/ui/icons'
import { SettingsPages } from './settings/constants'
import { useAtomValue, useSetAtom } from 'jotai'

export function BanNotice(): JSX.Element | null {
    const session = useAtomValue(Session)
    const localization = useLocalization()
    const [modalOpen, setModalOpen] = useState(true)
    const setSettingsModalOpen = useSetAtom(SettingsModalOpen)
    const element = useMemo(() => {
        if (session.authenticated && session.information.banStatus === BanStatus.BANNED) {
            return (
                <Modal
                    isOpen={modalOpen}
                    onRequestClose={() => setModalOpen(false)}
                    type={ModalType.Large}
                    shouldCloseOnOverlayClick={false}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <ModalBody
                        style={{
                            position: 'relative',
                        }}
                    >
                        <CloseButton onClick={() => setModalOpen(false)}>
                            <div />
                        </CloseButton>
                        <Title>{localization.ban.banned[0]}</Title>
                        <Text>
                            <a href="https://novelai.net/terms" target="_blank" rel="noreferrer">
                                {localization.ban.banned[1]}
                            </a>
                        </Text>
                        <Text>{localization.ban.banned[2]}</Text>
                        <Text>
                            {localization.ban.banned[3]}
                            <SubtleButton
                                style={{
                                    marginLeft: '10px',
                                    marginTop: '5px',
                                }}
                                onClick={() => {
                                    setModalOpen(false)
                                    setSettingsModalOpen(SettingsPages.Account)
                                }}
                            >
                                <LinkIcon
                                    style={{
                                        height: '16px',
                                        width: '16px',
                                    }}
                                />
                            </SubtleButton>
                        </Text>
                    </ModalBody>
                </Modal>
            )
        }
        return null
    }, [
        localization.ban.banned,
        modalOpen,
        session.authenticated,
        session.information.banStatus,
        setSettingsModalOpen,
    ])

    return element
}

const ModalBody = styled.div`
    background-color: ${(props) => props.theme.colors.bg1};
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme.colors.bg3};
    max-width: 500px;
    padding: 20px;
    margin: 20px;
`

const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    color: ${(props) => props.theme.colors.warning};
    margin-right: 30px;
`

const Text = styled.div`
    margin-bottom: 10px;
    color: ${(props) => props.theme.colors.textMain};
`
