import { useMemo } from 'react'
import { getUserSetting } from '../data/user/settings'
import { PaddleOpusID, PaddleScrollID, PaddleTabletID } from '../globals/constants'
import { Localization } from '../globals/state'
import en from '../localization/en'
import jp from '../localization/jp'
import enIcon from '../assets/images/lang_en.svg'
import jpIcon from '../assets/images/lang_jp.svg'
import { User } from '../data/user/user'
import { useAtomValue } from 'jotai'
import { deepMerge } from '../util/util'

export type Localization = typeof en

export function fillLocalization(str: string, ...args: any[]): string {
    if (typeof str !== 'string') {
        return ''
    }

    // Instances of [x] will be replaced.
    // Example: fillLocalization('Hello [0]!', 'World') -> 'Hello World!'
    return str.replace(/\[(\d+)]/g, (match, index) => {
        let partNum = Number.parseInt(match)
        if (Number.isNaN(partNum)) {
            partNum = index
        }
        return args[partNum] ?? ''
    })
}

// In some cases, we have a string with placeholders that must span multiple elements.
// This function will split the string into an array of strings.
// Example: splitFillLocalization('Hello [0]!', 'World') -> ['Hello ', 'World', '!']
export function splitFillLocalization(str: string, ...args: any[]): string[] {
    if (typeof str !== 'string') {
        return ['']
    }
    const split = str.split(/\[(\d+)]/g).map((part: string, index): string => {
        if (index % 2 === 1) {
            const partNum = Number.parseInt(part)
            if (Number.isNaN(partNum)) {
                return part
            }
            return (args[partNum] as string) || ''
        }
        return part
    })
    return split
}

export enum Language {
    en = 'en',
    jp = 'jp',
}

export const languageNames = {
    [Language.en]: 'English',
    [Language.jp]: '日本語',
}

export const languageIcons = {
    [Language.en]: enIcon.src,
    [Language.jp]: jpIcon.src,
}

function replaceStrings(obj: any): any {
    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'string') {
            obj[key] = 'ダミー'
        } else if (typeof obj[key] === 'object') {
            replaceStrings(obj[key])
        }
    })
}

export function getLocalizationForUser(session: User): Localization {
    let base = {
        ...en,
    }

    // For testing purposes, replace all strings of base with 'ダミー'
    // replaceStrings(base)

    const settings = session.settings

    switch (getUserSetting(settings, 'uiLanguage')) {
        case Language.jp: {
            base = deepMerge(base, jp) as typeof base
        }
    }
    return base
}

export const useLocalization = (): typeof en => {
    const localization = useAtomValue(Localization)

    return localization
}

export const useLocalizedSubscriptionIdToName = (): ((id: number) => string) => {
    const localization = useLocalization()

    return useMemo(() => {
        return (id: number) => {
            switch (id) {
                case PaddleOpusID: {
                    return localization.tier.opus
                }
                case PaddleScrollID: {
                    return localization.tier.scroll
                }
                case PaddleTabletID: {
                    return localization.tier.tablet
                }
                default: {
                    return localization.tier.unknown
                }
            }
        }
    }, [localization])
}

export const useLocalizedTierNumberToName = (): ((tier: number) => string) => {
    const localization = useLocalization()

    return useMemo(() => {
        return (tier: number) => {
            switch (tier) {
                case 1: {
                    return localization.tier.tablet
                }
                case 2: {
                    return localization.tier.scroll
                }
                case 3: {
                    return localization.tier.opus
                }
                default: {
                    return localization.tier.unknown
                }
            }
        }
    }, [localization])
}

// This function is for finding missing localization strings.
// It will return an object with the missing strings.
// Missing strings are strings that exist in the base localization but not in the diff localization.
// The object is searched recursively.
function getLocalizationDiff(base: any, diff: any): any {
    const output: any = {}
    Object.keys(base).forEach((key) => {
        if (typeof base[key] === 'string') {
            if (!(key in diff)) {
                output[key] = base[key]
            }
        } else if (typeof base[key] === 'object') {
            const diffValue = diff[key]
            if (typeof diffValue === 'object') {
                const diffResult = getLocalizationDiff(base[key], diffValue)
                if (Object.keys(diffResult).length > 0) {
                    output[key] = diffResult
                }
            } else {
                output[key] = base[key]
            }
        }
    })
    return output
}

// Strings to paired csv
function getLocalizationCSV(base: any, secondary: any): any {
    let baseMap: any = {}
    let secondaryMap: any = {}
    // Flatten by adding dashes
    function flatten(obj: any, path: string[] = []): any {
        if (typeof obj !== 'object') {
            return { [path.join('-')]: obj }
        }
        return Object.keys(obj).reduce((output, key) => {
            return { ...output, ...flatten(obj[key], [...path, key]) }
        }, {})
    }
    baseMap = flatten(base)
    secondaryMap = flatten(secondary)
    // print out csv
    let output = ''
    Object.keys(baseMap).forEach((key) => {
        output += `${key}\t${baseMap[key]}\t${secondaryMap[key]}`.replace(/\n/g, '\\n') + '\n'
    })
    return output
}

// Gets localization as csv, but only for strings in base and not in secondary
function getLocalizationCSVDiff(base: any, secondary: any): any {
    let baseMap: any = {}
    let secondaryMap: any = {}
    // Flatten by adding dashes
    function flatten(obj: any, path: string[] = []): any {
        if (typeof obj !== 'object') {
            return { [path.join('>')]: obj }
        }
        return Object.keys(obj).reduce((output, key) => {
            return { ...output, ...flatten(obj[key], [...path, key]) }
        }, {})
    }
    baseMap = flatten(base)
    secondaryMap = flatten(secondary)
    // print out csv
    let output = ''
    Object.keys(baseMap).forEach((key) => {
        if (!(key in secondaryMap) || secondaryMap[key] === undefined) {
            output += `${key}\t${baseMap[key]}\tUNTRANSLATED`.replace(/\n/g, '\\n') + '\n'
        }
    })
    return output
}
