/* eslint-disable max-len */
export default {
    lang: 'jp',
    tier: {
        paper: 'Paper',
        tablet: 'Tablet',
        scroll: 'Scroll',
        opus: 'Opus',
        unknown: 'Unknown / None',
    },
    general: {
        tier: 'ティア',
        account: 'アカウント',
        other: 'その他',
        help: 'ヘルプ',
        logout: 'ログアウト',
        login: 'ログイン',
        version: 'バージョン',
        beta: 'ベータ版',
        email: 'メール',
        password: 'パスワード',
        endSession: 'セッションを終了',
        anlas: 'Anlas',
        signIn: 'サインイン',
        signUp: 'サインアップ',
        logIn: 'ログイン',
        note: '注意：',
        welcomeBack: 'おかえりなさい！',
        loading: '読み込み中…',
    },
    cta: {
        manage: '管理',
        change: '変更',
        request: 'リクエスト',
        confirm: '確認',
        cancel: 'キャンセル',
        save: '保存',
        upgrade: 'アップグレード',
        yes: 'はい',
        ok: 'OK',
        gotIt: '了解',
        readMore: '続きを読む',
        manageSubscription: 'サブスクリプションの管理',
        overwrite: '上書き',
        startWriting: '書き始める',
        submit: '送信する',
        subscribe: '登録',
        unsubscribe: '配信停止',
        clickHere: 'ここをクリック',
    },
    common: {
        download: 'ダウンロード',
        delete: '削除',
        defaultX: 'デフォルト： [0]',
        applyAndSave: '適用＆保存',
        import: 'インポート',
        export: 'エクスポート',
        anonymouseTrial: '匿名トライアル',
        processing: '処理中',
        notSubscribed: 'サブスクライブされていません',
        somethingWentWrong: '問題が起きました！',
        copy: 'コピー',
        hide: '非表示',
        reveal: '表示',
        unknown: '不明',
        back: '戻る',
        giftKey: 'ギフトキー',
        optional: '(任意)',
        fileTypeNotSupported: 'ファイル形式がサポートされていません。',
        maximumFileSize: 'ファイルサイズは最大 [0]MB までです。',
        expand: '広げる',
        collapse: '折りたたむ',
        dontShowThisAgain: '今後は表示しない。',
        learnMore: 'もっと詳しく',
        skip: 'スキップ',
        fix: '修復',
        selectAnOptionToContinue: '使用するものを選択してください。',
        storyteller: 'ストーリーテラー',
        textAdventure: 'テキストアドベンチャー',
        shuffle: 'シャッフル',
        stories: '物語',
        generateInline: 'インラインを生成',
        memory: 'メモリー',
        authorsNote: '作者のメモ',
        undo: '元に戻す',
        redo: 'やり直し',
        retry: 'リトライ',
        lorebook: 'ロアーブック',
        tokenProbabilities: 'トークン確率',
        cancel: 'キャンセル',
        send: '送信',
        reload: 'リロード',
        closeModal: 'モーダルを閉じる',
        selectAll: 'すべて選択',
        deselect: '選択解除',
        search: '検索',
        enabled: '有効',
        disabled: '無効',
        generate: '生成',
        story: '物語',
        xTokens: '[0]トークン',
        tokens: 'トークン',
        changedMyMind: 'やっぱりやめる',
        noStorySelected: '物語が選択されていません。',
        ignore: '無視',
        lDisable: '無効にする',
        lEnable: '有効にする',
        characters: '文字',
        token: 'トークン',
        accept: '承諾',
        paymentProviderFailedToLoad:
            '決済代行会社のロードに失敗しました。ページをリロードしてください。一部の地域ではPaddleのサービスをご利用いただけません。このエラーが続く場合は、サービスの対象地域であるかご確認ください。',
        settings: '設定',
    },
    authFormGeneral: {
        getStarted: '始めましょう',
        email: 'Eメール',
        repeatEmail: 'Eメールの再確認',
        repeatPassword: 'パスワードの再確認',
        emailEmptyWarning: 'Eメールアドレスが未入力です',
        emailPasswordEmptyWarning: 'Eメールアドレスまたはパスワードが未入力です',
        rememberMe: '設定を記憶',
        loginRemembered: 'ログイン情報は30日間記憶されます',
        loginNotRemembered: 'ログイン情報は記憶されません',
        credentialsSub:
            'ログインに使用する情報を適切に管理してください。ローカル暗号化により、登録されているEメールアドレスやパスワードを忘れた場合、リモートで保管されている物語やAIモジュールへのアクセスが永久に失われます。',
        captchaSub:
            'このサイトはreCAPTCHAによって保護されており、Googleプライバシーポリシーと利用規約が適用されます。',
        naiTermsNote: {
            '0': '登録を行うことで、NovelAIの',
            '1': 'プライバシーポリシーと利用規約',
            '2': 'に同意したことになります。',
        },
        googleTermsNote: {
            '0': 'このサイトはreCAPTCHAによって保護されており、Google',
            '1': 'プライバシーポリシー',
            '2': 'と',
            '3': '利用規約',
            '4': 'が適用されます。',
        },
        marketingEmails: '当社からの最新情報をメールで受け取りたいですか？',
        emailWhitelistWarning: {
            '0': '注意',
            '1': 'お使いのメールプロバイダーは無料トライアルにアクセスすることができません。お手数ですが、製品のサブスクリプションをご利用ください。',
        },
    },
    passwordReset: {
        resetPassword: 'パスワードの再設定',
        resetPassWordSub:
            'このフォームを送信すると、パスワードを再設定する処理の手順が書かれたリンクがメールで送られます。',
        encryptionSub:
            'ローカル暗号化により、パスワードの再設定を行うとリモートで保管されている物語やAIモジュールへのアクセスが永久に失われます。',
    },
    storyDelete: {
        deleteIt: '削除します！',
        deleteStory: 'ストーリーを削除しますか？',
        areYouSure: '本当に"[0]"を削除したいですか？',
        cannotBeUndone: '元に戻せません。',
        delete: '削除',
    },
    features: {
        imageGeneration: '画像生成',
        aiModuleTraining: 'AIモジュールトレーニング',
        tokenizer: 'トークンナイザー',
        tutorial: 'チュートリアル',
        tips: '一口ヒント',
        docs: '関連ドキュメント',
    },
    modal: {
        userSettings: 'ユーザー設定',
        aiModules: 'AIモジュール',
        lorebook: 'Lorebook（ロアーブック）',
        storySettings: '物語設定',
        contextViewer: 'コンテキストビューアー',
    },
    image: {
        recommended: 'オススメ',
        generateText: '生成',
        paintNewImage: '自分でペイントする',
        uploadImage: '画像をアップロードする',
        modelSettings: 'モデル詳細設定',
        modelSettingsTTSteps: 'ステップ： ノイズを処理する回数です',
        modelSettingsTTScale:
            'スケール：　高スケールではAIはプロンプトに忠実になり、細部までハッキリと描きます。低スケールでは対象物の描画が曖昧になる代わりに、自由でクリエイティブな表現を楽しめます',
        portrait: '縦型',
        landscape: '横型',
        square: '正方形',
        custom: 'カスタム',
        small: '小サイズ',
        normal: '普通サイズ',
        large: '大サイズ',
        largeplus: '大サイズ＋',
        wallpaper: '壁紙',
        tokenCounter: '[1] トークンの内 [0] トークンを使用中',
        undesiredContent: '除外したい要素',
        lowQuality: '低品質',
        badAnatomy: '身体の破綻',
        none: '指定なし',
        addQualityTags: '品質タグを加える',
        addQualityTagsSubOff: 'あなたのプロンプトがそのまま使われます。',
        addQualityTagsSubOn: '品質タグをプロンプトに付け加えることで、生成物の品質が向上します',
        steps: 'ステップ',
        scale: 'スケール',
        seed: 'シード値',
        seedSub: 'シード値を入力',
        advancedSampler: 'サンプラー',
        numberOfImages: '同時生成数',
        history: '履歴',
        historyTT:
            '履歴欄内の画像をCtrl+クリックすると、その画像の生成時に使用した設定をインポートできます（初期画像を除く）。',
        settings: '設定',
        naidFurryDesc: '人外キャラクターや動物キャラクターを作成する為の開発中のAIモデルです。（非推奨）',
        naidFullDesc: '拡張されたデータセットで訓練されたオリジナルモデルの1つ。（非推奨）',
        naidCuratedDesc: 'オリジナルモデルの1つ。（非推奨）',
        naidAnime2Desc: '当社の旧モデル（非推奨）',
        naidAnime3Desc: '当社の最先端のモデル（推奨）',
        naidFurry3Desc: '当社の最先端のモデル（推奨）',
        downloadZIP: '一括で圧縮保存',
        selected: '選択中:',
        draw: 'ペン',
        erase: '消しゴム',
        select: '選択',
        save: '保存',
        cancel: 'キャンセル',
        colorPicker: 'スポイト',
        penSize: 'ペンサイズ',
        pressureSens: '筆圧感知',
        resizeCanvas: 'キャンバスサイズを変更',
        lowQualityPlusBadAnatomy: '低品質 + 人体描写の破綻',
        manageSubscription: 'サブスクリプションを管理',
        autoSmea: '自動nai_smea',
        autoSmeaChecked: '大きな画像サイズでの生成では nai_smea が自動選択されます',
        autoSmeaUnchecked: 'nai_smea は自動で選択されません.',
        imageResolution: '画像サイズ',
        useSmea: 'SMEAを使用',
        useSmeaTooltip: 'Smea版サンプラーは高解像度で高い性能を発揮します。',
        useSmeaTooltipDisabled: 'このサンプラーはSMEAに対応していません。',
        autoSmeaTooltip: '一定のサイズ以上の生成ではSMEAサンプラーが自動選択されます。',
        useDynVariant: 'Dynバリアント',
        useDynVariantTooltip:
            'サンプラーのSMEA Dynバリアントは、特徴的なSMEAのスタイルを取り込むことなく高解像度の画像の統一性を高められるように最適化されています。解像度の有効範囲は通常のSMEAより小さくなります。',
        prompt: 'プロンプト',
        undesiredContentSub: '除外したい要素を入力してください',
        promptSub: 'プロンプトを入力し、理想の画像を生成しましょう',
        disableTagSuggestions: '入力候補予測をやめる',
        promptGuidance: 'プロンプトガイダンス',
        guidance: '正確度',
        sampler: 'サンプラー',
        smea: 'SMEA',
        dyn: 'DYN',
        enhanceButton: '品質向上',
        enhanceImage: '画像の品質を向上させる',
        invalid: '無効',
        enhanceScale: '画像の拡大率',
        strength: '強度',
        noise: 'ノイズ',
        magnitude: 'レベル',
        showAdvanced: '高度な設定を表示',
        hideAdvanced: '表示を隠す',
        aiSettings: 'AI設定',
        addBaseImage: '参照用画像を追加(任意)',
        img2img: 'i2i',
        img2imgSub: '元画像を加工し新しい画像を生成する',
        palleteSwap: 'カラースワップ',
        palleteSwapSub: '構図を保ったままキャラクターのカラーパレットを変更',
        formLock: 'フォームロック',
        formLockSub: '輪郭や形を保ったまま新しいキャラクターを配置',
        scribbler: 'スクリブラー',
        scribblerSub: '落書きや白黒画像を芸術作品に変えましょう',
        buildingControl: 'ビルディングコントロール',
        buildingControlSub: '素敵な家や部屋をデザインしましょう',
        landscaper: 'ランドスケーパー',
        landscaperSub: '地形をデザインし美しい景色を造れます',
        undecided: 'ダミー',
        dontConvert: '変換しない',
        selectATool: 'ツール選択',
        didYouMean: 'もしかして？',
        result: '結果',
        palleteSwapDetailed: '調整メニューを表示',
        lowThreshold: '暗い部分を調整',
        highThreshold: '明るい部分を調整',
        alpha: 'アルファ',
        valueThreshold: '広さを調整',
        distanceThreshold: '奥行きを調整',
        generate1Image: '１枚のみ生成',
        generateXImages: '同時生成数：[0] 枚',
        dynamicThresholding: '緩和',
        cfgDelay: '多様性',
        cfgDelayTooltip:
            '生成画像の多様性と彩度を向上させるために、体型の決定後にガイダンスを有効にします。ただし、関連性が低くなる可能性があります。',
        enhance: '品質向上',
        variations: 'バリエーション',
        setAsInit: 'ベース画像利用',
        setAsInitImage: 'ベース画像として使用する',
        generateVariations: '別バージョンの画像を作成する',
        editImage: '画像を編集',
        undesiredContentPreset: '除外したい要素プリセット',
        maskDraw: 'マスク追加',
        maskErase: 'マスク消しゴム',
        saveAndClose: '保存して閉じる',
        removeInpaintingMask: '描いているマスクを消す',
        addInpaintingMask: '描いてマスクを追加する',
        clear: 'クリア',
        resetAllSettings: '全ての設定をデフォルトに戻しますか？',
        imageWithMetadataFound: '設定データを発見！',
        imageWithMetadataFoundSub: '画像、設定、プロンプトをインポートできます',
        importImage: '画像をインポート',
        importSettings: '設定をインポート',
        importSettingsPlusSeed: '設定＋シード値をインポート',
        toolInfluence: 'ツール影響度',
        upscale: '拡大',
        controlnetUndecidedError: '画像入力モードを選択するか、ベース画像を削除してください',
        dynamicThresholdingTooltip: '高プロンプト従属度で起こるノイズを減らします。',
        copyToClipboard: 'クリップボードにコピー',
        pinImage: '画像をピン留めする',
        downloadImage: '画像をダウンロード',
        copyToSeed: 'シード値をコピー',
        detatchUc: 'このタブを解除',
        reattachUc: 'このウィンドウをタブに戻す',
        promptChunkTooltip: 'プロンプト設定',
        resetSettings: '設定リセット',
        removePinnedImage: 'ピン留めした画像を外す',
        goToImage: '画像へ',
        old: '元画像',
        new: '新しい',
        imageSettings: '画像設定',
        editInpaintingMask: 'マスクを編集する',
        addOriginalImage: '元画像を加える',
        addOriginalImageChecked:
            '元のイメージが変更されるのを防ぎますが、マスクの縁に沿って継ぎ目が発生する可能性があります。',
        addOriginalImageUnchecked:
            'マスクの縁に出来る継ぎ目を防止しますが、元イメージがわずかに変わる可能性があります。',
        changeSize: 'サイズを変更',
        cropClosestValid: '有効生成サイズまでクロップ',
        shiftEdges: '端をシフト',
        resize: 'リサイズ',
        convert: '変換',
        regenerate: '再生成',
        imageTooLargeUpscale: '画像サイズが1024x1024より大きいです',
        compareWithOriginal: '元の画像と比較',
        convertUsingControlTool: 'コントロールツールを使用して変換',
        retryConversion: '変換を再試行',
        useOriginal: '元画像を使用',
        inpaintImage: '画像をインペイントする',
        inpainting: 'インペイント',
        inpaintingSub: '画像の一部を変更',
        mask: 'マスク',
        light: '軽い',
        heavy: '強い',
        negativePromptGuidance: '除外したい要素の強さ',
        negativePromptGuidanceTooltip: '100%以外の値を設定すると、生成時間とAnlasのコストが増加します。',
        image2imageNotSupported: '選択されたモデルでは Image2Image がサポートされていません。',
        controlToolsNotSupported: '選択されたモデルでは Control Tools がサポートされていません。',
        inpaintingNotSupported: '選択されたモデルではインペインティングがサポートされていません。',
        importPrompt: 'プロンプトをインポートする',
        qualityTagsEnabled: '品質タグ有効',
        introducingNaid2: 'NovelAI Diffusion 3.0をご紹介！',
        introducingNaid2Sub:
            '知識、制御性、一貫性、スタイルなど、あらゆる面で以前のモデルより何倍も優れています！',
        introducingNaid2Sub2: '',
        introducingNaid2SubSub:
            '自動的にこの新しいモデルに設定されましたが、お望みなら元に戻すこともできます。',
        ucPresetEnabled: '除外したい要素プリセットが有効です',
        advancedSettings: '詳細設定',
        cfgRescale: 'プロンプトガイダンスの再調整',
        or: '',
        randomize: 'ランダム化',
        randomPrompt: 'ランダム・プロンプト',
        noiseSchedule: 'ノイズ設定',
        legacyPromptBehaviour: '旧バージョン用のプロンプトのインポートが検出されました',
        legacyPromptBehaviour2:
            '画像を再現するために、古い動作を使用するようにフラグが設定されています。設定またはプロンプトを変更すると、このフラグはリセットされます。',
        addChristmas: 'クリスマス要素を追加',
        heavyPlusDeAI: '強い＋AIっぽさ軽減',
        naidXLFullDesc: '',
        naidXLCuratedDesc: '',
        humanFocus: '人間に重点を置く',
        automaticDownload: '自動ダウンロード',
        automaticDownloadSubOn: '画像は生成後に自動ダウンロードされます',
        automaticDownloadSubOff: '画像は生成後に自動ダウンロードされません',
        imageTooLong: 'この画像は生成時間が長すぎます。',
        image: '画像',
        sameFeel: 'バイブストランスファー',
        sameFeelSub: '画像を変えてビジュアルを維持する。',
        samefeelInformationExtracted: '抽出情報',
        samefeelImageStrength: '参照強度',
        sameFeelUnsuppportedModel: 'このモデルではバイブストランスファーはサポートされていません。',
        imageImportTitle: 'この画像で何をしたいですか？',
        importImage2Image: 'image2image',
        importSameFeel: 'バイブストランスファー',
        importThisImageHasMetadata: 'この画像にはメタデータが含まれています！',
        importThisImageHasMetadataSub: '代わりにそちらをインポートしますか？',
        importMetadata: 'メタデータをインポート',
        importSeed: 'シードをインポート',
        sessionExpired: 'セッションが中断されました。再度ページを読み込んでください。',
        diffEditSub: 'ダミー',
        add: 'ダミー',
        subtract: 'ダミー',
        sameFeelInitImageError: 'ダミー',
        importFor: 'ダミー',
        sendToImageTools: 'ディレクターツールに送信',
        cannotCopyHistorySettings: 'この画像から履歴設定を複製できません。',
        imageTools: 'ディレクターツール',
        imageToolsSub: '豊富なAIツールで画像を編集する。',
        modelRemovedSoon: 'このモデルは近日中に削除されます。',
        promptMixTooMany: 'プロンプトミックスの最大数[0]を超過しています。超過した[1]個は無視されます。',
        sdLicenseLead:
            'NovelAI Diffusionを使用するには、先にStable Diffusionのライセンスを読み、同意する必要があります:',
    },
    dashboard: {
        welcomeBack: 'ようこそ',
        createNewStory: '新しい物語を生成',
        importStory: '文章を読み込む',
        generateImages: '画像を生成',
        tts: '文章自動読み上げ',
        accountStatus: '現在のサブスクリプション',
        latestUpdate: '最近のアップデート情報',
        allUpdates: '過去のアップデート',
        recentStories: '最近の物語',
        lastEdited: '更新日:',
        imageGenerator: '画像ジェネレーター',
        imageGeneratorSub: '最先端の画像生成モデルで、アニメ、そしてケモノ系の画像を生成！',
        visualize: 'お気に入りのキャラクターをビジュアル化しよう。',
        dashboard: 'ダッシュボード',
        defaultName: '作者',
        imageGen: '画像生成',
        manageAccount: 'アカウント管理',
        latestNews: '最新情報',
        changelog: 'アップデート情報',
        scrollToViewMore: 'スクロールしてもっと見る',
        continueAStory: '物語の続きを書く',
        lastUpdatedUnknown: '最終更新日不明',
        startYourFirstStory: '最初の物語を書く',
    },
    menubar: {
        accountSettings: 'アカウント設定',
        novelAIDiscord: 'NovelAI公式ディスコード',
        yourStories: '作った物語',
        filters: 'フィルター',
        display: '表示',
        compactView: 'コンパクト表示',
        showOnTop: '先頭に表示',
        shelves: '本棚',
        favorites: 'お気に入り',
        localStories: 'ダウンロードした物語',
        sortBy: '表示順',
        mostRecent: '最終更新日',
        alphabetical: 'アルファベット順',
        creationDate: '制作日',
        searchSub: '物語を検索',
        duplicateStoryTT: '物語をコピー',
        downloadStoryTT: '物語をダウンロード',
        deleteStoryTT: '物語を削除',
        editInfoTT: '編集する',
        usesEditorV2: 'エディターV2を使用する',
        newStory: '新しい物語',
        newShelf: '新規本棚',
        importFile: 'ファイルをインポート',
        searchShelf: 'この本棚を検索',
        trialGenerationsRemaining: '残りのテキスト生成回数は [0]/[1] です。',
        freeTrial: '無料お試し',
        trialTokenCount: 'あなたはAIコンテキストを[0]トークン得ています。我々の[1]に相当します。',
        trialTier: 'スクロール・ティア',
        trialSeenEnough: 'もう十分ですか？',
        trialPlansLink: 'プランをチェック',
        trialCreateAccount: 'アカウントを作成',
        sideMenuHoverTitle: 'お気に入りへのクイックアクセス',
        sideMenuHoverSub: '物語の横にあるハートアイコンをクリックすると、ここに表示されます。',
        categoryOther: 'その他',
        categoryHelp: 'ヘルプ',
        desktopOnly: 'PCのみ',
    },
    settings: {
        aiSettings: 'AI詳細設定',
        interface: '画面表示設定',
        theme: '色テーマ',
        account: 'アカウント',
        currentTier: '現在のティア',
        yourSubEnds: '現在のサブスクリプションは [0] まで有効です。',
        penName: 'ペンネーム',
        emailUnverified: '有効なEメールアドレスを入れてください。',
        deleteAccount: 'アカウント消去',
        notPossibleWhileSubscribedError: 'サブスクリプションを解除した後に有効です。',
        showAccountID: 'アカウントIDを表示',
        textToSpeech: '文章読み上げ',
        defaults: 'デフォルト設定',
        hotkeys: 'ショートカットキー設定',
        support: 'サポート',
        changeLog: 'アップデート情報',
        yourSubExpired: '[0] にサブスクリプションは解約されます',
        uiLanguage: 'UI言語設定',
        couldntSaveSettings: '設定を保存できませんでした： [0]',
        debugStoryNotFound: 'ストーリー一覧にストーリーが見つかりません。',
        debugStoryDeleted: 'ストーリーは削除されました。',
        debugStoryDeleteFailed: 'ストーリーの削除に失敗しました。',
        debugStoryDeleteFailedReason: 'ストーリーの削除に失敗しました：[0]',
        debugStoryDownloadFailed: 'ストーリーのダウンロードに失敗しました。',
        debugStoryDownloadFailedReason: 'ストーリーのダウンロードに失敗しました：[0]',
        debugStoryNotRemote: 'ストーリーはリモートではありません。',
        debugStoryCloneFailedReason: 'ストーリーのクローンに失敗しました： [0]',
        debugStoryCloned: 'ID [0] のストーリーがクローンされました。',
        debugHideSettings: 'デバッグ設定の非表示',
        debugStoryDownload: 'デバッグストーリーのダウンロード',
        debugStoryDownloadPlaceholder: 'ストーリーIDを入力し、「ダウンロード」をクリックしてください。',
        debugStoryDelete: 'デバッグストーリーの削除',
        debugStoryDeletePlaceholder:
            'ストーリーIDを入力し、「削除」をクリックしてください。元に戻すことはできません。',
        debugLogprobsCount: 'ログプローブ数',
        debugPreventModuleOverride: 'モジュールのオーバーライド防止',
        debugPreventModuleOverrideChecked: '選択されたモジュールは常に生成に使用されます。',
        debugPreventModuleOverrideUnchecked: '選択されたモジュールは特定の状況でオーバーライドされます。',
        debugTrimExcessWhitespace: '余分な余白を取り除く',
        debugTrimExcessWhitespaceChecked: '余分な空白はAIに送られる前に文脈から削除されます。',
        debugTrimExcessWhitespaceUnchecked:
            '余分な空白はAIに送られる前にコンテキストから削除されません。余分な空白は出力の質を著しく低下させることがあります。',
        debugTrimExcessWhitespaceNote: '余分な空白には、末尾の空白と重複した改行文字が含まれます。',
        debugIgnoreRemoteStoryConflicts: 'リモートストーリーの競合を無視',
        debugIgnoreRemoteStoryConflictsChecked:
            'リモートストレージ内の競合は無視され、ローカルでの変更は常にリモートでの保存より優先されます。',
        debugIgnoreRemoteStoryConflictsUnchecked: 'リモートストレージの競合を検出します。',
        debugRemoteStoryClone: 'リモートストーリーのクローンをデバッグ',
        debugRemoteStoryCloneSub: '同じIDで同じリモートストーリーを作成',
        debugRemoteStoryClonePlaceholder: 'ストーリーIDを入力し、「クローン」をクリックしてください。',
        debugClone: 'クローン',
        debugDownloadRawRemoteMeta: '生のリモートメタをダウンロード',
        debugDownloadRawRemoteContent: '生のリモートコンテンツをダウンロード',
        aiResponses: 'AIの応答',
        streamAiResponses: 'AIの応答をストリーミングする',
        streamAiResponsesChecked: 'AIの応答はストリーミングされ、トークンごとに表示されます。',
        streamAiResponsesUnchecked: '生成が終了すると、テキストが一度に表示されます。',
        streamedResponseDelay: 'ストリーミング応答の遅延',
        continueResponseToEndOfSentence: '文の終わりまで応答を続ける',
        continueResponseToEndOfSentenceChecked: '文の終わりが見つかるまで応答が続けられます。',
        continueResponseToEndOfSentenceUnchecked: '応答は正常に終了します',
        hypebot: 'HypeBot',
        hypebotCommentOutput: 'コメントの出力',
        hypebotSetCommentOff: 'コメント生成をオフにする',
        hypebotOff: 'オフ',
        hypebotSetCommentAuto: 'コメント生成をオンにする',
        hypebotAuto: '自動',
        hypebotSetCommentPermanent: 'コメント生成をオンにし、ダイアログを常に表示',
        hypebotPermanent: 'パーマネント設定',
        hybebotClearComments: 'コメントをクリア',
        hypebotClearCommentsChecked: 'ストーリーの文章生成の際、コメントを自動的に消去します。',
        hypebotClearCommentsUnchecked: 'ストーリーの文章生成の際、コメントを自動的に消去しません。',
        hybebotCommentChance: 'コメントの頻度',
        hypebotAvatar: 'コメントのアバター',
        hypebotSelectLabel: 'コメントのアバターを選択',
        experimental: '試用版',
        preamble: '前文',
        preambleChecked:
            '低コンテキストで生成を向上させられるように、コンテキストに短い前文が追加されます。実際の挙動はモデルにより異なります。',
        preambleUnchecked: 'コンテキストに前文は追加されません。',
        defaultBias: 'デフォルトバイアス',
        defaultBiasChecked:
            'デフォルトのバイアスが適用され、dinkus(***)とasterism(⁂) が生成される可能性が低減されます。',
        defaultBiasUnchecked: 'デフォルトバイアスは適用されません。',
        bidirectionalInlineGeneration: '双方向インライン生成',
        bidirectionalInlineGenerationChecked:
            'インライン生成には２つのポイント間でテキストを生成する特別なモデルが使用されます。',
        bidirectionalInlineGenerationUnchecked: 'インライン生成にはストーリーモデルが使用されます。',
        enableLogprobs: 'トークン確率の有効化',
        enableLogprobsChecked: {
            '0': '生成リクエストを行うと、応答時にトークン確率を返します。',
            '1': 'リトライの隣のボタンから確認することができます。',
        },
        enableLogprobsUnchecked: '生成リクエストを行った際、トークン確率は返されません。',
        editorLogprobs: 'トークン確率エディター',
        editorLogprobsChecked: 'トークン確率は最新の応答のエディターにも表示されます。',
        editorLogprobsUnchecked: 'トークン確率はエディターに表示されません。',
        maxContextTokens: '最大コンテキストサイズ',
        maxContextTokensNoLimit: '無制限',
        maxContextTokensNote:
            '注：実際の制限値はサブスクリプションのティアとモデルによって異なります。この設定は最大コンテキストサイズを人為的に制限するためのものです。',
        uiLanguageLabel: '言語を選択する',
        textSettings: 'テキスト設定',
        interfaceFontSize: 'インターフェースのフォントサイズ',
        lineSpacing: '行間',
        paragraphSpacing: '段落間',
        maxLineWidth: '行の最大幅',
        outputFontSize: '出力時のフォントサイズ',
        paragraphIndent: '段落のインデント',
        buttonScale: 'ボタンのサイズ',
        interactionSettings: 'インタラクション設定',
        gestureControls: 'ジェスチャー制御',
        gestureControlsChecked: 'タッチデバイス上でスワイプするとサイドバーが開閉します。',
        gestureControlsUnchecked: 'ジェスチャー制御は無効化されています。',
        swapContextMenuControls: 'コンテキストメニュー切り替え制御',
        swapContextMenuControlsChecked:
            '右クリックで標準のコンテキストメニューが開きます。Ctrl+右クリックでNAIコンテキストメニューが開きます。',
        swapContextMenuControlsUnchecked:
            '右クリックでNAIコンテキストメニューが開きます。Ctrl+右クリックで標準のコンテキストメニューが開きます。',
        otherSettings: 'その他の設定',
        inputBox: '入力ボックス',
        inputBoxChecked: '入力ボックスが表示されます。',
        inputBoxUnchecked: '入力ボックスが非表示になります。',
        outputSpellcheck: 'エディタースペルチェック',
        outputSpellcheckChecked: 'スペルチェックは（サポート中のブラウザの）エディターで有効です。',
        outputSpellcheckUnchecked: 'スペルチェックはエディターで無効化されています。',
        outputSpellcheckWarning:
            'お使いのブラウザのバージョンではスペルチェックのパフォーマンスに問題があります。スペルチェックが自動的に無効化されました。',
        editorLoreKeys: 'ロア―ブックキーエディター',
        editorLoreKeysChecked: '"現在アクティブなロア―ブックに入力されたキーはエディター内で太字になります。',
        editorLoreKeysUnchecked: 'エディター内のロア―ブックキーに特別な書式は適用されません。',
        showTips: 'ヒントを表示',
        showTipsChecked: 'ヒントはエディターの下に表示されます。',
        showTipsSessionDisabled: 'このセッションでは一時的にヒントが無効化されます。',
        showTipsUnchecked: 'ヒントは表示されません。',
        useEditorV2: '新しいストーリーにV2エディターを使用する',
        useEditorV2Checked: '新しいストーリーでは新しいドキュメントエディターが使用されます。',
        useEditorV2Info: {
            '0': '新しいドキュメントエディターは、パフォーマンスの向上、テキストの書式設定、モバイルコンテキストメニュー、および追加機能のためのセクションの動的ロードをサポートしています。',
            '1': 'ストーリーでは作成時のエディターが使用され続けます。',
            '2': '機能パリティに達したため、今後のある時点でV1は完全に置換されます。よってV2エディターの使用を推奨します。',
        },
        useEditorV2Unchecked: '新しいストーリーでは古いストーリーエディターが使用されます。',
        editorHighlighting: 'エディターのハイライト機能',
        editorHighlightingChecked: 'エディター内の文章はオリジナルに基づきハイライトされます。',
        editorHighlightingUnchecked: 'エディター内の文章はハイライトされません。',
        contextViewerColors: 'コンテキストビューアーの色',
        contextViewerColorsChecked: 'コンテキストビューアー内の文章はオリジナルに基づき色分けされます。',
        contextViewerColorsUnchecked: 'コンテキストビューアー内の文章はデフォルト色を使用します。',
        showStoryTitle: 'ストーリーのタイトル表示',
        showStoryTitleChecked: 'エディター上部にストーリーのタイトルが表示されます。',
        showStoryTitleUnchecked: 'エディター上部にストーリーのタイトルが表示されません。',
        showEditorToolbox: 'エディターツールボックスの表示',
        showEditorToolboxChecked:
            'V2エディター内で文章を選択すると、文章のフォーマット変更などのオプション付きのツールボックスが表示されます。',
        showEditorToolboxUnchecked:
            'V2エディター内で文章を選択しても、文章のフォーマット変更などのオプション付きのツールボックスが表示されません。',
        editorUnloadSectionsRange: 'パラグラフの可視性範囲',
        editorUnloadSectionsRangeSub:
            '指定された範囲外にある段落は、V2エディターに読み込まれません。この設定により特に長いストーリーで編集のパフォーマンスが向上します。',
        noUnloading: '読み込まれない部分はありません',
        highlightSpeech: '会話部分の強調',
        highlightSpeechDesc:
            'エディター内の会話部分（クオーテーションマーク間の文章）を斜体にし、少しだけ透明にして強調したい場合は選択。反転を選択した場合、代わりに会話ではない部分が斜体になり少し透明になります。',
        editorV2Only: 'V2エディターでのみ使用可能な機能です。',
        highlightSpeechOff: 'オフ',
        highlightSpeechOffLabel: '会話部分の強調を無効にする',
        highlightSpeechOn: '強調',
        highlightSpeechOnLabel: '会話部分を強調する',
        highlightSpeechInverted: '反転',
        highlightSpeechInvertedLabel: '会話以外の部分を強調する',
        showIdenticon: 'Identiconの表示',
        showIdenticonChecked: 'ユニークなIdenticonがアカウントに表示されます。',
        showIdenticonUnchecked: 'テーマ特有のデフォルトアバターがアカウントに表示されます。',
        useMinibar: 'ミニバーを表示（PCのみ）',
        useMinibarChecked: 'ミニバーは画面の左側に表示されます。',
        useMinibarUnchecked: 'ミニバーは表示されません。',
        invalidThemeFile: '無効なテーマファイルです。',
        importedTheme: 'テーマエディターにテーマがインポートされました。',
        saveTheme: 'テーマを適用して保存',
        importTheme: 'テーマファイルをインポート',
        exportTheme: 'テーマファイルをエクスポート',
        defaultThemes: 'デフォルトのテーマ',
        themeEditor: 'テーマエディター',
        downloadAllFailed: 'ダウンロードがすべて失敗しました。',
        persistentTokenFailed: '永続的トークンの取得に失敗しました。',
        accountId: 'アカウントID',
        getPersistentToken: '永続的APIトークンを取得',
        persistentToken: '永続的APIトークン',
        persistentTokenDesc: '以下はAPIのアクセスに使用される永続的トークンです。',
        persistentTokenShowFull: 'フル・トークンを表示',
        persistentTokenCopied: 'トークンをクリップボードにコピーしました。',
        persistentTokenNote:
            '注：一度に使用できる永続的トークンは1つのみ。新しいトークンを作成すると古い方は無効となります。このウィンドウを閉じると同じトークンを表示することはできません。',
        persistentTokenOverwrite: '永続的APIトークンを上書きします',
        persistentTokenOverwriteDesc:
            '既存の永続的APIトークンがあります。新規作成すると古い方は無効となります。',
        persistentTokenOverwriteConfirm: '本当に上書きしたいですか？',
        localStorageUnavailable: 'ローカルストレージは現在使用不可で機能しません。',
        downloadAllStories: 'すべてのストーリーをダウンロード',
        downloadingAllStories: 'すべてのストーリーをダウンロード中…',
        changeDetailsMustEnterExisting: '現在のメールアドレスとパスワードを入力してください。',
        changeDetailsMustEnterNew: '新しいメールアドレスまたは新しいパスワードを入力してください。',
        changeDetailsEmailFormat: 'メールアドレスはメールアドレスの形式で入力してください。',
        changeDetailsPasswordRequirements: 'パスワードは8文字以上で入力してください。',
        changeDetailsPasswordMatch: 'パスワードが一致しません。',
        changeDetailsFailed: '詳細の変更に失敗しました。',
        changeDetailsSuccessEmail: '詳細が変更されました。新しいメールアドレスに確認メールが送信されました。',
        changeDetailsSuccess: '詳細が変更されました。',
        changeDetailsIncorrectAccessKey: 'ユーザー名とパスワードの組み合わせが間違っています。',
        changeDetailsResentEmail: '確認メールを再送しました。',
        changeDetailsRefreshClaimActions:
            'メールを確認後、ページを更新して50回の無料テキスト生成を獲得してください。',
        changeDetailsAccountDeletionEmail: 'アカウント削除の確認メールを送信しました。',
        changeDetailsAccountDeletionFollow:
            'メールに記載されているリンクに従って、アカウントの削除を確認してください。',
        changeDetailsOldEmail: '旧メールアドレス',
        changeDetailsNewEmail: '新しいメールアドレス',
        changeDetailsPassword: 'パスワード',
        changeDetailsCurrentEmail: '現在のメールアドレス',
        changeDetailsCurrentPassword: '現在のパスワード',
        changeDetailsNewPassword: '新しいパスワード',
        changeDetailsConfirmNewPassword: '新しいパスワードの確認',
        changeDetailsEmail: 'メールアドレス',
        changeDetailsSendConfirmationEmail: '確認メールの送信',
        changeDetailsConfirmationEmailDesc: {
            '0': '以下にメールアドレスを入力すると、アカウント削除の確認メールが送信されます。',
            '1': 'アカウントを削除すると、ストーリーやその他のコンテンツが永久に失われますのでご注意ください。残しておきたいコンテンツについては必ず事前にエクスポートすることをお勧めします。アカウント設定の「すべてのストーリーをエクスポート」オプションを使ってエクスポートすることもできます。',
        },
        changeDetailsSendDeletionConfirmationEmail: '削除確認メールの送信',
        changeDetailsPenNameLabel: 'ペンネームの変更',
        changeDetailsEmailLabel: 'メールの変更',
        changeDetailsPasswordLabel: 'パスワードの変更',
        changeDetailsVerifyFreeActions:
            '50回の無料テキスト生成を獲得するにはメールアドレスを照合してください。',
        changeDetailsDeleteAccountLabel: 'アカウントの削除をリクエストする',
        ttsAreaDescription: 'この欄に入力されたテキストは、テスト/ダウンロードボタン使用時に適用されます。',
        ttsSource: '文章読み上げのソース',
        ttsSourceLocalDesc: 'ローカルTTSではユーザーのブラウザで有効な音声合成機能が使用されます。',
        ttsSourceStreamedDesc:
            'ストリーミングTTSには、より高品質でNovelAIがホストするモデルが使用されますので、有効なサブスクリプションが必要です。トライアルとして無料で100回の生成をお試しいただけます。',
        ttsLocalUnsupported: 'お使いのブラウザはローカルTTSをサポートしていません。',
        ttsStreamed: 'ストリーミング',
        ttsLocal: 'ローカル',
        ttsUsingLocal: 'TTSにブラウザの音声合成APIが使用されます。',
        ttsUsingStreamed: 'TTSにNovelAIのリモートTTSサービスが使用されます。',
        ttsNoMediaSource:
            'お使いのブラウザは MediaSource をサポートしていません。ストリーミングTTSは機能しますが、遅延が大きくなる可能性があります。',
        ttsNoMpeg:
            "お使いのブラウザは MediaSource タイプとして 'audio/mpeg' をサポートしていません。ストリーミングTTSは機能しますが、若干遅延が大きくなる可能性があります。",
        ttsOff: '文章読み上げ機能はオフになっています。',
        ttsSpeakOutputs: '出力の読み上げ',
        ttsSpeakOutputsChecked: '出力はTTSによって読み上げられます。',
        ttsSpeakOutputsUnchecked: '出力は読み上げられません。',
        ttsSpeakInputs: '入力の読み上げ',
        ttsSpeakInputsChecked: '出力に加えて、入力もTTSによって読み上げられます。',
        ttsSpeakInputsUnchecked: '入力は読み上げられません。',
        ttsSpeakInputsNoEffect: '出力の読み上げが無効化されている場合は、変わりません。',
        tssSpeakHypebot: 'HypeBotコメントの読み上げ',
        ttsSpeakHypebotChecked: 'HypeBotのコメントはTTSによって読み上げられます。',
        ttsSpeakHypebotUnchecked: 'HypeBotのコメントは読み上げられません。',
        ttsSpeakHypebotButDisabled: 'すでに、HypeBotは無効化されています。',
        ttsSpeakHypebotDisabled: 'HypeBotは無効化されています。',
        ttsLocalSettings: 'ローカルTTS設定',
        ttsSelectAVoice: 'ボイスを選択してください',
        ttsTesting: 'テスト中...',
        ttsTestVoice: 'テストボイス',
        ttsVolume: '音量',
        ttsSpeed: '速度',
        ttsPitch: 'ピッチ',
        ttsStreamedSettings: 'ストリーミングTTSの設定',
        ttsModel: 'モデル',
        ttsVoice: 'ボイス',
        ttsUnknownVoice: '未知のボイス',
        ttsVoiceSeedDesc: '指定されたシードに基づいたボイスを使用します。',
        ttsVoiceSeedLabel: 'ストリーミング音声読み上げシード',
        ttsVoiceSeedPlaceholder: 'ここにシードを入力してください',
        ttsRandomizeSeed: 'ランダム抽出',
        ttsRandomizeSeedLabel: 'ランダム抽出されたシード',
        ttsSeedNote:
            '注：一般的な名字から始めると、音程やイントネーションに影響を与えます。今後TTSの開発が進むにつれて、取得したどのシードのボイスも変更される可能性があります。',
        ttsStoryVoice: 'ストーリーのボイス',
        ttsHypebotVoice: 'Hypebotのボイス',
        ttsSavedVoices: '保存されたボイス',
        ttsSelectVoiceLabel: '編集するボイスを選択してください。',
        ttsNoSavedVoices: 'ボイスがありません。まず追加してください。',
        ttsUnnamedVoice: '無名のボイス',
        ttsName: 'ボイス名',
        ttsSeed: 'シード',
        ttsSaveVoice: 'ボイスを保存',
        ttsDeleteVoice: 'ボイスを削除',
        ttsDeleteVoiceButton: '削除します！',
        ttsDeleteAreYouSure: '本当にボイス"[0]"を削除しますか？',
        ttsDeleteCannotBeUndone: '元に戻せません。',
        ttsSpeedDownloadNote: '注：ダウンロードされた音声の速度には影響しません。',
        defaultStorageLocation: 'デフォルトの保管場所',
        defaultStorageLocationLocal: 'ローカル',
        defaultStorageLocationRemote: 'サーバー',
        defaultStorageLocationLocalDesc: '新規＆取り込まれたストーリーはローカルにのみ保存されます。',
        defaultStorageLocationRemoteDesc:
            '新規＆取り込まれたストーリーはローカルに保存され、リモートでは暗号化されて保管されます。',
        defaultStorageLocationBackupNote:
            'ブラウザのキャッシュがクリアされたり、アカウントへのアクセス権を失ったりした場合に備え、ストーリーのエクスポートとバックアップを強く推奨します。',
        defaultAIModel: 'デフォルトのAIモデル',
        defaultAIModelDesc: '新しいストーリーではこのモデルがデフォルトで使用されます。',
        defaultAIModelSelectLabel: 'AIモデルを選択',
        defaultPreset: 'デフォルトプリセット',
        defaultPresetDesc: '新しいストーリーでは選択したプリセットがデフォルトで使用されます。',
        defaultAIModule: 'デフォルトのAIモジュール',
        defaultAIModuleDesc: '新しいストーリーでは選択したモジュールがデフォルトで使用されます。',
        defaultAIModuleNotSupported: '選択されたAIモデルはモジュールに非対応です。',
        lorebookGenerationSettings: 'ロア―ブック生成設定',
        lorebookGenerationSettingsDesc:
            'ロア―ブックジェネレーターによって使用されたモデルと設定のプリセットを変更します。',
        lorebookGenerationModel: 'ロア―ブック生成モデル',
        lorebookGenerationModelSelectLabel: 'ロア―ブック生成に使用するAIモデルを選択',
        lorebookGenerationPreset: 'ロア―生成プリセット',
        lorebookGenerationLegacy: 'レガシーロアー生成',
        lorebookGenerationLegacyChecked: {
            '0': 'ロアージェネレーターに基づいた統一性の低いFew-shot プロンプトが使用されます。',
            '1': '選択したストーリーのモジュールは、選択したモデルで使用可能な場合、生成に使用されます。任意の生成タイプはサポートされていません。',
        },
        lorebookGenerationLegacyUnchecked: 'ロアージェネレーターには予約されたモジュールが使用されます。',
        giftKeys: 'ギフトキー',
        purchaseGiftKey: '新しいギフトキーを購入',
        giftKeyKey: 'キー',
        giftKeyDateCreated: '作成日',
        giftKeyTier: 'ティア',
        giftKeyStatus: '状態',
        noGiftKeys: 'ギフトキーがまだありません！',
        giftKeyCopiedToClipboard: 'ギフトキーがクリップボードにコピーされました。',
        giftKeyRedeemed: '引き換えられました',
        giftKeyAvailable: '有効です',
        confetti: '紙吹雪エフェクト',
        imageGenerationConfettiAnimation: '画像生成紙吹雪アニメーション',
        imageGenerationConfettiAnimationChecked: '画像生成中に紙吹雪が表示されます。',
        imageGenerationConfettiAnimationUnchecked: '画像生成中に紙吹雪が表示されません。',
        storiesConfettiAnimation: '物語ページ紙吹雪アニメーション',
        storiesConfettiAnimationChecked: '物語ページに紙吹雪が表示されます。',
        storiesConfettiAnimationUnchecked: '物語ページに紙吹雪が表示されません。',
        giftKeyDisabled: '現在、ギフトキーの購入はできません。',
        giftKeyRemoved: '不正使用により、ギフトキーの購入は無期限に廃止しています。',
        giftKeyPurchasingDisabled: 'ギフトキー（購入不可）',
        debugPreventPreamble: '前文を使用しない',
        debugPreventPreambleChecked:
            'コンテキストには前文が追加されません。これにより、問題や正しくない動作が生じる可能性があります。',
        debugPreventPreambleUnchecked: '様々な要因に応じて、コンテキストに前文が追加されます。',
        special: 'スペシャル',
    },
    subscription: {
        tierCards: {
            perMonth: '/月',
            titleMain: '使用可能なAI機能',
            titleExtra: 'その他特典',
            unlimitedText: '無限の可能性を秘めた文章生成',
            tokenMemory: '[0] トークン',
            tokenMemorySub: 'トークン数 ~[0] はAIが記憶できるおおよその文字数を示しています。',
            anlasSub: '画像生成のための通貨。毎月補充されます。',
            TTS: 'TTS生成（文章読み上げ機能）',
            TTSSub: '生成された音声読み上げボイスの心地よいサウンドで、ストーリーに命を吹き込みましょう。',
            imageGen: '画像生成機能',
            imageGenSub: '高度な画像生成モデルを試しましょう！',
            imageGenSubUnlimited: '普通と小サイズの画像生成をAnlasの消費なしで無限に楽しめます！**',
            accessBest: 'Kayra、最高のAIストーリーテラーへのアクセス',
            accessBestSub: 'その他全てのAIストーリーテラーにアクセスできます',
            freeGenerations: '無料テキスト生成',
            experimentalFeatures: '全ての試験的機能へのアクセス',
            experimentalFeaturesSub: '他の誰よりも早く最新でクールな機能にアクセスしましょう！',
            freeTTS: '100回分の無料音声合成',
            finePrintImage: '**画像生成では 1024x1024ピクセル、28ステップ、同時生成数一枚の時',
            accessLess: '当社の小規模ながら最速の概念実証AIストーリーテラーClioにアクセス',
            paperPrice: '無料',
            unlimited: '無制限',
            tokensOfMemory: '記憶文字数',
            tokensOfMemorySub: 'AIが記憶できる文字数（～4文字=1トークン）',
            nCharacters: '～ [0] 文字',
            nTokens: '[0] トークン',
            extraFeatures: 'その他の特典',
            advancedAI: '高度なAI',
            bestValue: 'ベストバリュー',
            forTheEnthusiast: 'スペシャリスト向け',
        },
        tierPricing: 'Tier段階ごとの価格',
        checkoutDisabled:
            '現在メンテナンス中の為、試行に失敗しました。しばらくしてからもう一度お願いします。',
        subLongerActivate:
            'サブスクリプションは既に購入済みです。アクティベートまで暫く時間をいただく場合があります。',
        alreadyPurchased: {
            '0': 'サブスクリプションは既に購入済みです。処理に時間がかかっています。',
            '1': '数分後にもう一度お試しください。',
            '2': '３０分ほど処理に時間をいただく場合がございます。また、アジア圏からのサブスクリプションのアクティベートには更に４時間ほど遅れが生じる事もございます、ご了承ください。問題の解決しない場合は、[0]　までご連絡ください.',
        },
        asiaPurchaseDelay:
            '注：アジア圏からのサブスクリプションの購入処理には４時間ほど遅れが生じる事もございます。ご了承ください。',
        paymentProcessing:
            'お支払いはこれで完了です。アカウント開設まで３０分程お待ちください。もし問題が解決しない場合は、support@novelai.net　までご連絡ください。',
        processingGiftKey: 'ギフトキーのアクティベートに時間がかかっています。',
        cannotUseSubGiftKeyUnlessGifted:
            'サブスクリプション用ギフトキーの使用には制限があります。現在のサブスクリプションがギフトキー由来の場合、新しいサブスクリプション用ギフトキーを使う事は出来ません。',
        cannotSubGiftKey:
            '現在既にサブスクリプションに契約中です。サブスクリプション用のギフトキーは使用できません。',
        upgradeProcessing:
            'サブスクリプションのアップグレード成功。あなたのサブスクリプションは [0] になりましたが、私達側の処理に時間がかかっています。少々お待ちください・・・',
        needAsubscription: 'このアクションにはサブスクリプションの購入が必要です',
        notSubscribed: '有効なサブスクリプションを持っていないようです！',
        upgradeSubscriptionQ: 'サブスクリプションをアップグレードしますか？',
        takeMeThere: '進む',
        noTakeMeBack: '戻る',
        youAreSubscribedTo: ' あなたのサブスクリプションは [0] です',
        yourSubscriptionRenews: 'あなたのサブスクリプションは [0] に更新されます.',
        yourSubscriptionEnds: 'あなたのサブスクリプションは [0] に終了し、更新されません',
        yourSubScriptionHighRisk:
            'あなたのサブスクリプションは現在、当社の決済業者によって高リスクに分類されています。これは解決まで1日程度かかる場合があります。',
        unsubscribe: 'サブスクリプション購入停止',
        upgradeSubscription: 'サブスクリプションの種類を変更',
        updatePaymentMethod: '支払方法を更新',
        replaceSubscription: 'サブスクリプション交換',
        activateGiftKey: 'ギフトキーをアクティベート',
        activate: 'アクティベート',
        giftKeySuccess: 'アクティベート成功!',
        giftKeySuccessSubscription: 'じゃーん！ [0] サブスクリプションがアクティベートされました！',
        giftKeySuccessAnlas: 'じゃーん！ [0] Anlasを受け取りました！',
        giftKeySuccessSubscriptionAndAnlas:
            'じゃじゃーん！ [0] サブスクリプションがアクティベートされ、 [1] Anlasを受け取りました',
        upgradeSubscriptionInfo:
            'サブスクリプションの交換によって新しいサブスクリプションの当月分の支払いが生じます。現在のサブスクリプションの残り日数に応じた割引が適応されます。',
        upgradeSubscriptionConfirm: 'サブスクリプションを [0] から [1] にアップグレードしてよろしいですか？',
        upgradeSubscriptionCharge: '今すぐ当月分 [0] USドルの支払いが生じます',
        upgradeConfirm: 'アップグレードします',
        replaceSubscriptionInfo:
            'サブスクリプションの交換によって新しいサブスクリプションの当月分の支払いが生じ、古いサブスクリプションは破棄されます。',
        giftKeyConfirmation: 'ギフトキー使用確認',
        giftKeySubscriptionAlreadySubbedConfirm: {
            '0': 'このギフトキーには([0], [1] )日分のサブスクリプションが含まれますが、お客様は既にサブスクリプションを契約中のようです',
            '1': 'このギフトキーに含まれる新しいサブスクリプションに交換しますか？',
        },
        giftKeySubcriptionAlreadySubbedAndAnlasConfirm: {
            '0': 'このギフトキーには([0], [1] )日分のサブスクリプションと([2]) Anlasが含まれますいますが、お客様は既にサブスクリプションを契約中のようです',
            '1': 'Anlasだけを取得しますか?',
            '2': 'Anlasのみ取得するか、新しいサブスクリプションに交換するかを選択できます',
        },
        redeemJustAnlas: 'ギフトキーでAnlasのみを取得',
        replaceMySubscription:
            '現在のサブスクリプションを終了し、このギフトキーに含まれる新しいサブスクリプションに交換しますか？',
        thankYouForCompletingTheSurvey: 'ご意見、ご感想をいただきありがとうございました！',
        submitSurvey: '提出する',
        unsubscribeLinkMissing:
            'サブスクリプション解除のリンクがありません。サポートまでお問い合わせください。',
    },
    purchase: {
        mustBeLoggedIn: 'Anlasを購入するにはログインが必要です。',
        purchasedXAnlas: '[0]Anlasを購入しました！',
        purchasedNonSubAnlas:
            '購入成功！\n Anlasがお客様のアカウントに追加されるまで、最大30分以上かかる場合があります。予想以上に時間がかかる場合は、support@novelai.net までご連絡ください。',
        purchaseDescription1:
            'ここでは、AIモジュールのトレーニングや画像生成のための追加のAnlasを購入することができます。',
        purchaseDescription2: '定期購入のAnlasは、毎月定期購入に応じて補充されます。',
        yourSubscriptionAnlas: 'あなたの定期購入のAnlas:',
        yourPaidAnlas: 'あなたの有償Anlas:',
        anlasPerUSD: 'Anlas/ドル',
        purchase: '購入',
        doYouReallyWantTo: '本当に',
        purchaseTheFollowing: '以下の内容を購入しますか？',
        xAnlasForXUsd: '[0]Anlas を [1]ドルで購入',
        highRisk:
            'あなたの購読取引は、私たちの支払いプロバイダで高リスクとしてマークされています。 このステータスが解除されるまで、Anlasを購入することはできませんが、通常24時間以内に解除されます。',
        confirmPurchase: '購入を確定する',
        cancel: 'キャンセル',
    },
    landing: {
        typewriter: {
            castle: '湾の向こうの城',
            steam: '蒸気で動く都市',
            city: '自然に飲み込まれた廃虚',
            childhood: '子供の頃の思い出',
            fracture: '壊れた過去',
            mountains: '山々の旅',
            desert: '砂漠の中の王国',
            magic: '魔法の国',
            woods: '霧の森',
            moon: '月夜の呪い',
            war: '戦時国家',
            age: '人の世',
            case: '新たな事件',
            country: '静かな田舎暮らし',
            river: '川辺の散策',
            evening: '暗澹たる夕方',
            twisted: '歪んだ現実',
            coastline: '活気に満ちた海岸線',
            gardens: '庭園の散歩',
            inn: '暖かい宿',
            research: '忘れ去られた研究',
            insurrection: '武装蜂起',
            library: '魔法の図書館',
            dreamscape: '儚い夢物語',
            expedition: '未知への探検',
            ancient: '古代の預言',
            market: '賑やかな市場',
            sea: '穏やかな海の夜',
            crystal: '水晶洞窟の探検',
            lost: '道迷い',
            egg: '卵？',
            picnic: '湖畔でのピクニック',
            distant: '遥かな山々',
            hallway: '不吉な予感のする廊下',
            heroes: '英雄と悪者',
            temple: '隠された寺院',
            inspiring: '感動の物語',
            forgotten: '忘れ去られた場所',
            love: '愛情と欲望',
            otherworldly: '異世界の存在',
            storm: '荒海',
            rwar: '戦争の轟音',
            rural: '田舎社会',
            whale: 'スカイクジラの捕獲',
            strange: '奇妙な出来事',
            dawn: '新しい一日の始まり',
            alchemy: '錬金術の街',
            night: '真夜中',
            end: '時代の終わり',
            heaven: '天国の庭園',
            hell: '地獄の庭園',
            gods: '神々の家',
            court: '宮廷',
            menagerie: '宮殿のメナジェリー',
            underworld: '冥界への道',
            institute: '山頂の研究所',
            artist: '芸術家の苦悩',
            sun: '太陽の恵み',
            workshop: '人里離れた工房',
            beyond: '理解を超えた物事',
            frozen: '凍てつく大地の旅',
            romance: '思いがけないロマンス',
            depths: '見えざる深淵',
            victorian: 'ヴィクトリア時代',
            alien: '未知の場所',
            library2: '変わった図書館',
            torn: '張り裂けそうな世界',
        },
        title: 'NovelAI – AIストーリーテラー',
        blog: 'ブログ',
        discord: 'ディスコード',
        login: 'ログイン',
        writeAbout: 'について書いてみよう',
        description:
            'AI を活用して苦労せずにユニークなストーリーを構築したり、スリリングな物語を作ったり、魅惑的なロマンスを書いたり、ただふざけたりすることができます。検閲やガイドラインはありません。何でもありです！',
        learnMore: 'さらに詳しく',
        startForFree: '無料で書き始める',
        startForFreeNew: '無料で書き始める',
        whatIs: 'NovelAIとは？',
        intro1: 'NovelAIは、AIによる画像生成、ストーリーテリング、またはLLM（大規模言語モデル）を利用した想像力のサンドボックスのための月額制サービスです。',
        intro2: '当社の人工知能アルゴリズムはあなたの文章に基づいて人間が書くような文章を作成し、能力に関係なく誰でも質の高い文学作品を生み出すことを可能にします。当社の自然言語処理のプレイグラウンドでは実際の文献に基づいてトレーニングされた独自の AI モデルを使用し、前例のないレベルの自由を提供します。AIはあなたの視点とスタイルを維持しながら、インプットされた内容にシームレスに対応します。',
        toPricing: '価格設定へ',
        ourFeatures: '製品の特長',
        imgTitle: '画像生成でお気に入りのキャラクターをビジュアル化してみよう',
        imgSub: '強力なイメージモデルを使用し、最先端のアニメアートAIとその他のAIモデルであなたのストーリーの瞬間を描写します。',
        getStarted: '始める',
        adventure1:
            'あなたはぼんやりと、オーブの中に映るものを見つめ考えを巡らせる。それは世界中のランダムな画像だ。球体のなかを覗き込み、それらの画像が自分にとってどんな意味を持つのか熟考する。森、海、砂漠、山脈…　その意味について考えながら画像を眺め、数えきれないほどの時間を過ごす。',
        adventure2: '＞あなたはため息をつき、疲れた目をしばらくの間休ませる。「ひと休みしないと」',
        adventure3:
            'オーブの中の画像はその美しさに畏敬の念を抱かずにはいられないほど奇妙で異質だ。その色や形はあなたの魂に語りかけ、穏やかでさわやかな気分にさせてくれる。あなたの思考はさまよう。もっと深く覗くと、どんな不思議に出会えるんだろう？',
        whatWouldYouLikeToDo: 'あなたは何がしたいですか？',
        adventureTitle: 'ここからあなたの（テキストの）冒険を始めましょう',
        adventureSub:
            'オープンエンドがあまり好みではない場合、テキストアドベンチャーモジュールを有効にしましょう',
        adventureCta: '冒険を始める',
        customizeTitle: 'エディターをカスタマイズ',
        customizeSub:
            'テーマエディターでスペースを自分用にデザインしてください。フォント、サイズ、配色をいつでも自由に編集できます。',
        anywhereTitle: '作品をどこでも好きな場所で',
        anywhereSub: '家にいても外出先でも、いつでもNovelAIにアクセスして新しいものを書くことができます。',
        secureTitle: '作品を保護',
        secureSub:
            'あなたの作品を他の人が読むことはできません。当社のサーバーに保管されているすべての作品はXSalsa20により暗号化されています。',
        steerTitle: 'AI を新しい道へ誘導する',
        steerSub:
            'AIモジュールを使えば、特定のテーマを用いたり、有名な作家のスタイルを真似したり、独自のデータでAIを訓練することさえ可能です。',
        steerCta: '書き始める',
        useAiModules: 'AIモジュールで旅をさらに充実させよう',
        useAiModulesSub: 'モジュール化された豊富なツールを使って、作品を全く新しい方向へと導きます。',
        modules: 'AIモジュール',
        style: 'スタイル',
        styleSub: '好きな作家の真似をしましょう。',
        module1Title: 'テキストアドベンチャー',
        module1Sub: 'AIのダンジョンマスターとのスリリングな冒険を楽しみましょう。',
        module2Title: 'インストラクト',
        module2Sub: 'カスタマイズされたインストラクションにより、物語を正しい方向へ進めます。',
        module3Title: '文章補強機能',
        module3Sub: 'ディテールの質を上げ文章を補強することで最高のライティングを追求します。',
        theme: 'テーマ',
        themeSub: 'あなたの作品を特定のテーマに基づいて進めましょう。',
        module4Title: 'ダーク・ファンタジー',
        module5Title: 'ドラゴン',
        module6Title: '火星移住',
        expand: 'ストーリーの可能性を広げます',
        expandSub1: '当社のサービスは最大8192',
        expandSub2: 'トークン',
        expandSub3: 'のメモリを保持します。言うまでもなく、このAIは多くを記憶することができます。',
        tweak: 'AIの出力を微調整',
        tweakSub:
            '豊富なオプションで AI を制御しましょう。一貫性を保ってもいいですし、少しランダムにすることもできます。',
        legacies: '古い遺産を引き継ぐ',
        legaciesSub: 'AIDCATやAIDWIからインポートした作品をこの場所でよみがえらせましょう',
        lorebookTitle: '作品の世界観をコントロール',
        lorebookSub:
            'ロアーブックはあなたの作品の世界観をAIが逸脱することのないように、常に確認してくれる機能です。',
        lorebookCta: '世界を創造する',
        testimonialLead: 'ユーザーの声',
        testimonialTitle: 'ぜひご自分の目で確かめてみてください',
        testimonialTitleNew: 'ユーザーの声',
        beginYourAdventure: '冒険を始める',
        selectATier: 'プランを選択',
        selectATierAndBeginYourAdventure: 'ティアを選んで冒険を始めよう',
        getStartedForFree: '無料体験',
        fineprint:
            '*ユーザーの皆様は50回分の無料生成をお試しいただけます。アカウント作成をしていただいた時点で無料回数が50回まで補充されます。',
        termsOfService: '利用規約',
        contactUs: 'お問い合わせ',
        support: 'サポート',
        pressKit: 'プレスキット',
        updates: '更新情報',
        about: '会社概要',
        pricing: '価格',
        bringYourCharactersToLife: '登場人物に命を吹き込み、スリリングな物語や冒険を楽しもう',
        latestBlogPosts: '最新のブログ記事',
        testimonials: {
            '0': {
                '0': '「NovelAIは常に進化を続けている最高のツールです。',
                '1': '書き手の創造性を刺激するライティング補助的なこともできるし、ユーザーが一緒に楽しめるゲームでもある、マルチツールのような存在です',
                '2': '」',
                '3': 'felixchowmusic様',
            },
            '1': {
                '0': '「',
                '1': 'NovelAIのユーザーインターフェースと作者の自由度が気に入っています。',
                '2': '好きなだけシステムをいじれる機能と、すぐに利用できる適切なプリセットが組み込まれていて、それだけでもお金を払う価値があります」',
                '3': '匿名ユーザー様',
            },
            '2': {
                '0': '「素晴らしい。AIダンジョンの真の後継者です。',
                '1': 'さまざまなオプション、特にモジュールによって物語を制御することができます',
                '2': '」',
                '3': '匿名ユーザー様',
            },
            '3': {
                '0': '「好きな点はたくさんあります。ニーズに応えてくれる開発者、プライバシー性、',
                '1': '思いつくことを何でも書けるところ',
                '2': '」',
                '3': '匿名ユーザー様',
            },
            '4': {
                '0': {
                    '0': '「良いグラフィックカードを持っていないため、',
                    '1': 'AI Roguelite',
                    '2': 'で画像を生成するのにすごく時間がかかっていました。',
                },
                '1': 'NovelAIの画像は素晴らしく、そして生成も早いです。クレークと共にダンジョンを進むのは最高です！',
                '2': '」',
                '3': 'Panergicagony様',
            },
            '5': {
                '0': '「趣味で執筆をしているので、物語を書くためにAIを補助として使っています。アイデアが全く浮かばない時や実験したいことがある時ですね。',
                '1': '私の創作の幅を広げてくれる本当に素晴らしいサービスです',
                '2': '」',
                '3': 'Otviss様',
            },
        },
        status: '状態',
    },
    inspect: {
        metadata: 'メタデータ',
        title: 'タイトル',
        description: '詳細',
        generationTime: '生成時間',
        software: 'ソフトウェア',
        source: 'ソース',
        parameters: 'パラメーター',
        simplified: '簡易版',
        rawParameters: '未加工のパラメーター',
        requestType: '要求タイプ',
        requestTypes: {
            '0': '未確認の要求タイプです',
            PromptGenerateRequest: 'Text to Image',
            Img2ImgRequest: 'Image to Image',
            NativeInfillingRequest: 'インペイント',
        },
        noMetadata: '当画像にメタデータはありません',
        instructions:
            '画像のメタデータを確認する場合は、アップロードボタンをクリックまたはウインドウ内に画像をドラッグしてください',
    },
    chatpage: {
        contact: 'コンタクト',
        tags: 'タグ',
        name: '名前',
        species: '（生物学上の）種',
        gender: '性別',
        chooseOrEnterCustom: '選択または直接入力',
        male: '男性',
        female: '女性',
        na: '該当なし',
        pronouns: '代名詞',
        heHim: '彼/彼の',
        sheHer: '彼女/彼女の',
        persona: '人物像',
        appearance: '外見',
        neutral: '普通',
        angry: '怒り',
        tired: '疲れ',
        aroused: '興奮',
        embarrassed: '恥ずかしい',
        love: '愛、好き',
        worried: '心配',
        determined: '決意、やる気',
        happy: '幸せ、楽しい',
        scared: '怖い',
        excited: 'ワクワク、興奮、楽しみ',
        thinking: '考える、疑問に思う',
        shy: 'もじもじする、はにかむ',
        smug: 'ドヤ顔、自慢、得意げ',
        laughing: '笑う',
        hurt: '傷つく(精神的/肉体的)',
        sad: '悲しい',
        surprised: '驚く',
        nervous: '緊張や不安',
        confused: '混乱',
        disgusted: '嫌悪',
        bored: '飽きる',
        irritated: 'イライラする',
        playful: 'ふざける、面白がる',
        addChat: 'チャットを追加',
        addExample: '見本を追加',
        addDeletionResponse: '引止め言葉を追加',
        addBrain: 'ブレインを追加',
        export: 'エクスポート',
        importChats: 'チャットをインポート',
        chatN: 'チャット[0]',
        exampleN: '見本[0]',
        brainN: 'ブレイン[0]',
        deleteN: '引止め[0]',
        user: 'ユーザー',
        nMessages: '[0]メッセージ',
        deletionResponse: '引止め言葉',
        exampleChat: '見本チャット',
        exampleIsGreeting: '見本は挨拶ですか？',
        chatName: 'チャット名（整理用）',
        chatTags: 'チャットタグ',
        style: 'スタイル',
        conversation: '会話',
        roleplay: 'ロールプレイ',
        intimacy: '親しさ',
        stranger: '初対面',
        acquaintance: '知り合い',
        close: '親しい',
        romantic: '恋愛',
        adultsOnly: '成人専用ですか？',
        adultNo: 'いいえ',
        adultYes: 'はい',
        tagsOfUser: 'ユーザーのタグ',
        nameOfUser: 'ユーザーの名前',
        speciesOfUser: 'ユーザーの種',
        genderOfUser: 'ユーザーの性別',
        personaOfUser: 'ユーザーの人物像',
        appearanceOfUser: 'ユーザーの外見',
        pronounsUsedForUser: 'ユーザーの代名詞',
        scene: 'シーン',
        environment: '環境',
        summary: 'サマリー',
        deleteChat: 'チャットを削除',
        createNew: '新規作成',
        or: 'または',
        chooseFile: 'ファイルの選択',
        noFileChosen: 'ファイルが選択されていません',
        importWithUserAndContactReversed: 'ユーザーとコンタクトを入れ替えますか？',
        intro1: 'このページの使用方法',
        intro2: '編集ページは同じキャラクターのチャットグループを中心に構成されています。左側ではキャラクターの情報を設定でき、右側では、チャットの情報を設定できます。右側の、チャットの情報を設定できる場所は、チャットが選択されるまで開けません。',
        intro3: '上部のタブでチャットを切り替えることができます。プラスのアイコンをクリックすると新規作成できます。いずれかを実行すると今ご覧の説明ページは消えます。その横のボタンを押すと現在のチャットが jsonファイル形式でエクスポートされます。先ほどの画面で「新規作成」をクリックする代わりにそのファイルを選択し、インポートすることができます。自動保存機能はありませんので定期的に編集内容を保存するようにしてください。ダウンロードされたファイルのタイムスタンプを使えば常に最新のファイルを把握することができます。',
        intro4: 'チャットを選択すると、下に一対の入力欄が表示されます。右側がユーザー用、左側がコンタクト用です。タブキーを使ってユーザーとコンタクトを切り替えることができます。',
        intro5: 'エンターキーを押すとメッセージが送信されます。Shift + Enterで改行されます。',
        intro6: 'コンタクト用のメッセージ入力欄に付いている絵文字を変更すると、入力した文章と一緒に送られる絵文字を変えることができます。その横にある鍵のアイコンを使うと、メッセージを生成する度に感情を選ばなくても、新規メッセージに自動で同じ感情の絵文字が付きます。',
        intro7: 'メッセージを送信した後は、クリックすると編集可能になります。絵文字をクリックして、メッセージに付随する絵文字を変更することもできます。',
        intro8: '代名詞の欄は、必要に応じてカスタマイズされた代名詞を入力していただけます。',
        intro9: 'ユーザーのメッセージ入力欄の上部の名前をクリックするとユーザー名の変更が可能です。編集できないように見えますが、実はできます。',
        chatMetadata: 'チャットメタデータ',
        nameTitle: '名前/タイトル',
        noName: 'no name',
        deleteChatQuestion: 'チャットを削除しますか？',
        deleteChatAreYouSure: '本当にこのチャットを削除したいですか？',
        format1Text: '通常の会話文',
        format1Format: 'このように入力',
        format2Text: 'ナレーションおよびアクション',
        format2Format: '（このように入力）または(このように入力)',
        format3Text: '心の声、思考',
        format3Format: '【このように入力】',
        format4Text: 'テレパシー',
        format4Format: '＜このように入力＞または<このように入力>',
        format5Text: '前後の文脈と関係のない文章',
        format5Format: '((OOC: このように入力))',
        format6Text: '太字',
        format6Format: '**このように入力**',
        format7Text: '斜体の文字',
        format7Format: '_このように入力_',
        format8Text: '斜体の太字',
        format8Format: '**_このように入力_**',
        format9Text: '引用文',
        format9Format: '「このように入力」「このように入力」',
        format10Text: '翻訳文',
        format10Format: 'comme ça [TL: このように入力]',
        format11Text: '共有されたファイル',
        format11Format: '[記録媒体: 内容をこのように入力]',
        enterBrainContentHere: 'ブレインエントリーの内容をここに入力',
        actionChar: '(（',
        changedMyMind: '考え直す',
    },
    confetti: {
        confettiPopupEnableButton: 'お祝いを始めよう！',
        confettiPopupOptOutButton: 'オプトアウト：パーティーは苦手。遠慮します！',
        confettiPopupNote:
            'みんなで楽しみたい？パーティーに参加したい場合は、「テーマ」でお祝いのテーマに切り替えてください。',
        confettiPerfWarning: {
            '0': '一部のデバイスではアニメーションの適用でパフォーマンスが低下する場合がありますが、色テーマ設定からいつでも無効化できます。',
            '1': '',
            '2': '',
        },
    },
    contest: {
        title: 'タイトル：',
        titleSub: '作品の題名',
        textFieldOptional: 'テキストフィールド：（任意）',
        textField: 'テキストフィールド：',
        textFieldSub: '応募作品の簡単な説明や背景の情報をご記入ください。',
        email: 'メールアドレス：',
        emailSub: '賞品送付用のメールアドレスをご記入ください。',
        name: '名前：',
        nameSub: 'アーティスト名をご記入ください。',
        submit: '提出',
        currentSubmission: '現在の応募作品',
        deleteSubmission: '応募作品を削除',
        loginRequired: {
            '0': '応募には',
            '1': '登録とログイン',
            '2': 'が必要です。',
        },
        supportedFileTypes: 'サポート形式：.png',
        selectFile: 'ファイルを選択',
        backToDetails: '詳細に戻る',
        imageContestSubmission: '画像コンテスト応募作品',
        textContestSubmission: 'テキストコンテスト応募作品',
        submissionSuccess: '応募が完了しました！',
        submitToContest: 'コンテストに応募する',
        copiedPromptToClipboard: 'プロンプトがクリップボードにコピーされました。',
        copiedUCToClipboard: '除外したい要素がクリップボードにコピーされました。',
        saveImage: '画像を保存',
        recreate: '再現',
        submissionGallery: '応募作品ギャラリー',
        pleaseVote: '好きな作品全てに投票しよう！',
        mustBeLoggedInToVote: '投票には登録とログインが必要です。',
        uploadFailedNoMetadata: 'アップロード失敗：メタデータのない画像です。',
        uploadFailed: 'アップロード失敗：[0]',
        invalidFile: '無効なファイル',
        uploadFailedDuplicate: 'アップロード失敗：重複した応募が検出されました。',
        fillMandatoryFields: '必須項目は必ずご記入ください。',
        theWinners: '入賞者',
        firstPlace: '1位',
        secondPlace: '2位',
        thirdPlace: '3位',
        fourthPlace: '4位',
        fifthPlace: '5位',
        sixthPlace: '6位',
        seventhPlace: '7位',
        eighthPlace: '8位',
        ninthPlace: '9位',
        tenthPlace: '10位',
        nthPlace: '[0]位',
        developersChoice: '開発者特別賞',
        comedyChoicePrize: 'コメディー賞',
        submissionTitle: '作品名',
        vote: '投票',
        remove: '削除',
        submissionCloses: '応募作品の提出期間終了：2024年6月25日(火) 午後2:00',
        days: '日',
        day: '日',
        hours: '時間',
        hour: '時間',
        minutes: '分',
        minute: '分',
        seconds: '秒',
        second: '秒',
        countdownSeparator: '',
        votingClosed: '提出は締め切られました。まもなく投票が始まります。',
        againstRules: '誠に残念ですが、テーマやルールに沿っていない作品は除外させていただきました。',
        imageContest2024Theme: '幸運の数字「3」',
        textContest2024Theme: 'Three Years of Imagined Worlds: Memories',
        votingCloses: '投票終了日時：',
        votingClosesTime: '2024年6月29日(土) 午後3:59',
        seeResultsNow: '今すぐ結果を確認する',
        congratulationsPrizesDistributedShortly:
            '受賞者の皆様、おめでとうございます！賞品はまもなく配布されます。 NovelAIチームより御礼申し上げます！',
        yourSubmission: 'あなたの応募作品',
        byX: '作者：[0]',
        description: '説明',
        errorLoadingImages: '画像の読み込みエラー',
        approve: '承認',
        unapprove: '否認',
        displayedEntries: '応募作品の表示数',
    },
    links: {
        title: 'リンク - NovelAI',
        novelai: 'NovelAI',
        blog: 'ブログ',
        tutorialsAndGuides: 'チュートリアル＆ガイド',
        discord: 'Discord',
        twitter: 'X（旧 Twitter）',
        reddit: 'Reddit',
        youtube: 'YouTube',
        aetherroom: 'AetherRoom',
        anlatan: 'Anlatan',
    },
    ban: {
        full_banned: {
            '0': 'お客様のアカウントは不審な支払い行為により停止されています。',
            '1': '利用規約をご確認ください。https://novelai.net/terms',
            '2': 'アカウントへのアクセスは完全に制限されています。',
        },
        banned: {
            '0': 'お客様のアカウントは不審な支払い行為により停止されています。',
            '1': '利用規約をご確認ください。https://novelai.net/terms',
            '2': '今後、テキストや画像の生成、新規サブスクリプションの購入、アンラスの購入はできなくなります。',
            '3': '既に生成したストーリーがありましたら、[ユーザー設定] > [アカウント] > [すべてのストーリーをダウンロード] の手順でダウンロードしてください。',
        },
    },
    newsletter: {
        novelaiNewsletter: 'NovelAI ニュースレター',
        stayInformed: '当社の最新情報をお届けします。',
        subscribeToOurNewsletter: 'ニュースレターを登録',
        toSubscribeLater:
            '後で登録したり、Eメールの設定を管理するには、[0] 設定 > アカウントをご利用ください。',
        enterYourEmail: 'メールアドレスを入力してください。',
        youreSignedUp: 'ニュースレターに登録されました。',
        youllRecieve: '新しいアップデートやその他のニュースが発表されると、当社からメールが届きます。',
    },
    imageTools: {
        tools: {
            'bg-removal': '背景の除去',
            declutter: 'デクラッター',
            'declutter-keep-bubbles': 'デクラッター（吹き出しを残す）',
            lineart: '線画',
            sketch: 'スケッチ',
            colorize: 'カラー化',
            emotion: '感情',
            upscale: 'アップスケール',
        },
        toolTooltip: {
            'bg-removal': '画像から背景を削除し、キャラクターだけを残します。',
            declutter:
                '画像の上に描かれたテキストや吹き出しなどの「クラッター（雑然としたもの）」を取り除く機能です。',
            'declutter-keep-bubbles': 'ダミー',
            lineart: '画像の輪郭を描きます。',
            sketch: '画像をスケッチの段階に戻します。',
            colorize:
                'スケッチや線画の絵をカラフルに着色しましょう。カラー化の際、プロンプトを使ってガイドしたり、画像を少し修正することもできます。',
            emotion: '任意のキャラクターの表情を変更します。',
            upscale: 'ダミー',
        },
        prompt: 'プロンプト（オプション）',
        defry: '色補正',
        transform: '変換',
        masked: 'マスク',
        generated: '生成',
        blend: 'ブレンド',
        keepBubbles: '文字の吹き出しを残す',
        tooLarge: '選択された画像が大きすぎます。最高解像度は 1536 x 2048 ピクセルまで。',
        tooLargeForUpscale:
            '選択された画像は大きすぎてアップスケールできません。最大解像度は1024 x 1024 ピクセルまで。',
        emotionStrength: '感情の強度',
        emotionStrengths: {
            '0': 'ニュートラル',
            '1': '少し弱い',
            '2': '弱い',
            '3': 'まあまあ弱い',
            '4': 'とても弱い',
            '5': '一番弱い',
        },
        emotion: '感情',
        animeOnly: 'アニメのみ。ニュートラルの感情の画像から開始してください。',
    },
    models: {
        erato: {
            name: 'Llama 3 Erato',
            description: 'Meta Llama 3 70Bで構築した当社最大かつ最強のモデル',
        },
        kayra: {
            name: 'Kayra',
            description: '一から作り上げたモデルで、現在2位の能力をもつ勇ましい挑戦者',
        },
        clio: {
            name: 'Clio',
            description: '今なお高速で信頼性の高い当社のベテラン有力選手',
        },
        euterpe: {
            name: 'Euterpe',
            description: 'レガシーモデル(非推奨)',
        },
        krake: {
            name: 'Krake',
            description: 'かつて高性能を誇ったレガシーモデル(非推奨)',
        },
        sigurd: {
            name: 'Sigurd',
            description: 'レガシーモデル(非推奨)',
        },
        genji: {
            name: 'Genji',
            description: '日本語に対応したレガシーモデル(非推奨)',
        },
        snek: {
            name: 'Snek',
            description: 'Pythonコード用のレガシーモデル(非推奨)',
        },
        calliope: {
            name: 'Calliope',
            description: 'レガシーモデル(非推奨)',
        },
        cassandra: {
            name: 'Cassandra',
            description: 'OccultSageの試験的モデル',
        },
        hypebot: {
            name: 'HypeBot',
            description: '非表示の箇所です',
        },
        infill: {
            name: 'Infill',
            description: '非表示の箇所です',
        },
        blue: {
            name: '青',
        },
        red: {
            name: '赤',
        },
        green: {
            name: '緑',
        },
        purple: {
            name: '紫',
        },
        pink: {
            name: 'ピンク',
        },
        yellow: {
            name: '黄',
        },
        white: {
            name: '白',
        },
        black: {
            name: '黒',
        },
        unknown: {
            name: '不明なモデル',
            description: '不明なモデル',
        },
    },
    commonError: {
        clipboardUnavailable: 'クリップボードは使用できません。',
        failedToWriteToClipboardX: 'クリップボードへの書き込みに失敗しました: [0]',
        failedToReadFromClipboardX: 'クリップボードからの読み込みに失敗しました: [0]',
        cantSpeakAnEmptySelection: '選択範囲が空なので読み上げできません。',
    },
    giftkey: {
        purchaseError:
            'ギフトキーの受信中に問題が発生しました。メールをご確認ください。1時間経ってもギフトキーが届かない場合はサポートまでお問い合わせください。',
        giftKeyPurchased: {
            '0': 'じゃーん！こちらがあなたのキーです！',
            '1': 'じゃーん！こちらがあなたのキーです！',
            '2': 'アカウント設定またはメールで状態を確認できます。',
        },
        copyKey: 'キーをコピー',
        keyCopied: 'ギフトキーをクリップボードにコピーしました。',
        purchaseAGiftKey: '新しいギフトキーを購入',
        purchasesDisabled: {
            '0': '現在メンテナンス中のためチェックアウトが無効化されています。今後の最新情報をお待ちください。',
            '1': '購入済みのギフトキーが届くまでに通常より時間がかかるかもしれません。',
        },
        asiaPurchaseNotice:
            '注: 現在、アジア地域からのご購入については、ギフトキーの到着が遅くなる場合があります(最大4時間)。',
        purchaseKey: 'キーを購入',
    },
    screenshot: {
        screenshotDesigner: 'スクリーンショットデザイナー',
        showTitle: 'タイトルを表示',
        showDate: '日付を表示',
        showPenName: 'ペンネームを表示',
        showNaiLogo: 'NAIのロゴを表示',
        showColorHighlighting: '色ハイライトを表示',
        showColorLegend: '色の凡例を表示',
        showModel: 'AIモデルを表示',
        showBackground: '背景を表示',
        showComment: 'コメントを表示',
        showDialogueHighlight: '会話をハイライト',
        showConfetti: '紙吹雪を表示',
        fontSize: 'フォントサイズ',
        theme: 'テーマ:',
        widthPx: '幅(px):',
        writtenAlongside: '共同執筆者',
        toClipboard: 'クリップボードへ',
        downloadJpg: 'JPGでダウンロード',
        downloadPng: 'PNGでダウンロード',
    },
    remoteStorageError: {
        storyTooLarge: 'リモート保存のサイズを超過しています',
        storyTooLargeDesc:
            '物語「[0]」はリモート保存するには大きすぎるため保存できませんでした。物語はローカル保存に設定されます。',
    },
    updateNotes: {
        changeLog: 'アップデート情報',
        clickAnEntryForMoreInfo: '記事をクリックすると詳細が表示されます。',
        reloadThePage: '最新版を表示するにはページをリロードしてください',
    },
    updateNotifier: {
        thereAreUpdates: '利用可能なアップデートがあります！',
        description:
            '現在お使いのNovelAIは最新の状態ではないようです。ページを更新して最新アップデートを入手してください。',
        reloadPage: 'ページをリロード',
        seeUpdates: 'アップデートを見る',
        couldntSaveStories: '物語を保存できませんでした。物語を確認して手動でリロードしてください。',
        failedToReload: 'ページのリロードに失敗しました。',
    },
    license: {
        lead: '以下の内容をお読みいただき、同意してください:',
        agree: '上記の事項に同意します',
    },
    remoteStorageModal: {
        remoteStorage: 'リモート保存',
        text1: {
            '0': 'あなたのアカウントは、物語を',
            '1': 'ローカルデバイスのみ',
            '2': 'に保存するよう設定されています。ローカル保存は永久に保存されるという意味ではなく、様々な理由でいつでも物語が失われる可能性はあります。',
        },
        text2: {
            '0': '当社のサーバーに物語を保存することを推奨します。',
            '1': '物語はローカルで暗号化され、あなた以外の人はアクセスできません。この設定を物語ごとに変更することも可能です。',
        },
        remoteStorageHint:
            'ローカル保存する場合は、必ず物語をエクスポートして手動でバックアップを取るようにしてください。\nアカウント設定からすべての物語をエクスポートできます。\nリモート保存に切り替えても既存の物語は自動的にはサーバーに移動されません。保存先は、個々の物語の設定で変更できます。',
        switchToRemoteStorage: 'リモート保存に切り替える',
        keepLocalStorage: 'ローカル保存のままにする',
    },
    tokenizer: {
        intro: {
            '0': 'テキストは、AIに送られる前にトークン化と呼ばれる処理で数字に変換されます。トークンは、AIがテキストを読み、解釈する仕組みです。',
            '1': '平均的なトークンの長さは英語で約4文字、日本語で1から2文字ですが、多くの一般的な単語はそのままトークンになります。',
            '2': '現在選択しているモデルのトークナイザーは[0]です。',
        },
        selectATokenizerType: 'トークナイザータイプを選択',
        textInput: 'テキスト入力',
        idInput: 'ID入力',
        placeholder: 'ここに入力すると、トークン化された形式が下に表示されます。',
        formattingError: 'フォーマットエラー',
        text: 'テキスト',
        tokenIds: 'トークンID',
        tokensX: 'トークン: [0]',
        charactersX: '文字: [0]',
        tokenOmittedFromOutput: '(出力から省略されたトークン)',
    },
    tokenProbabilities: {
        title: 'トークン確率',
        before: '前',
        after: '後',
        totalNotShown: '非表示の合計%',
        description: {
            '0': '下のトークンをクリックして選択すると、生成される可能性のあったトークンの確率が表に表示されます。',
            '1': '各列:',
            '2': '- AIが考慮の対象とした上位10個の代替トークン',
            '3': '- 生成設定、バイアス、使用禁止の前、およびモジュールの適用後の確率(%)',
            '4': '- 生成設定、バイアス、使用禁止、およびモジュールの適用の後の確率(%)',
            '5': 'トークンはその確率に基づいて色分けされています:',
        },
        low: '低',
        medium: '中',
        high: '高',
    },
    stories: {
        llama3LicenseLead:
            'Llama 3 Eratoを使用するには、先にLlama 3のライセンスを読み、同意する必要があります:',
        failedLoadingTheEncoderX: 'エンコーダーのロードに失敗しました: [0]',
        duplicateStories: {
            importedCopyOfDuplicateStoryX: '重複する物語のコピーをインポートしました: [0]',
            deletedDuplicateStoryX: '重複する物語を削除しました: [0]',
            finishedFixingDuplicateStories: '重複する物語の修復が終わりました。',
            duplicateStoriesFound: {
                '0': 'この物語には複数のリモートコピーがあります。何らかの理由で、複数作成されてしまったようです。',
                '1': '修復ボタンをクリックすると、別々の物語への分離を試みます。',
                '2': 'ダウンロードボタンをクリックすると、[0]のバージョンのコピーをダウンロードします。物語の修復がうまくいかない場合には、こちらを推奨します。',
            },
            both: '両方',
            allX: '全[0]個',
        },
        noEditorAvailable: '利用可能なエディターがありません。',
        storyStart: {
            letsStartWriting: 'さあ、書き始めましょう！',
            storytellerSub: 'AIと一緒に執筆して、文章やアイデアをふくらませる',
            textAdventureSub: '会話や行動をベースに、AIが生成する冒険の旅を開始する',
        },
        scenarios: {
            chooseAScenario: 'シナリオを選択',
            loadingVotesFailed: '投票数のロードに失敗しました。',
            useTheBrowser:
                '下のブラウザーを使って面白そうなシナリオを選択し、内容を見てから開始することができます。',
            cantThinkOfAnyIdeas: 'アイデアが浮かばない？',
            pickOneOfOurs: '既存のシナリオをベースに書いてみましょう。',
            failedToLoadDefaultScenarios: 'デフォルトシナリオのロードに失敗しました。',
            viewAllScenarios: 'すべてのシナリオを見る',
            searchForScenarios: 'シナリオを検索',
            searchPlaceholder: 'タイトル、作者、タグ、視点で検索',
            noScenariosFound: 'シナリオが見つかりません。',
        },
        storyFailedToLoad:
            '物語のロードに失敗しました。内部エラーです。ページをリロードしてもう一度お試しください。',
        storyPlaceholder: 'ここにプロンプトを入力します...',
        inputPlaceholder: '入力をここに書きます',
        adventureModes: {
            DO: 'する',
            SAY: '言う',
            STORY: '物語',
        },
        switchToXMode: '[0]モードに切り替える',
        adventurePlaceholders: {
            default: '何をしたいですか？',
            DO: '何をしますか？',
            SAY: '何と言いますか？',
        },
        toolMenu: 'ツールメニュー',
        findInStory: '物語内を検索',
        contextMenu: {
            cut: '切り取り',
            ctrlX: 'Ctrl+X',
            copy: 'コピー',
            ctrlC: 'Ctrl+C',
            paste: '貼り付け',
            ctrlV: 'Ctrl+V',
            find: '検索',
            ctrlF: 'Ctrl+F',
            cutSelection: '選択範囲を切り取り',
            copySelection: '選択範囲をコピー',
            specialCharacter: '特殊文字',
            insertX: '[0]を挿入',
            specialCharacters: {
                litrpg: 'LitRPG',
                enspace: 'エンスペース',
                emspace: 'エムスペース',
                bullet: '黒丸',
                ellipsis: '省略符号',
                emdash: 'エムダッシュ',
                asterism: 'アステリズム',
                dinkus: 'Dinkus',
            },
            insertSpecialCharacterSubmenu: '特殊文字を挿入... サブメニュー',
            specialCharactersDescriptions: {
                litrpg: 'LitRPGゲームテキスト',
                enspace: '引用/抜粋',
                emspace: '詩/歌詞',
                dinkus: '場面/章の区切り',
            },
            addTo: '追加...',
            addToSubmenu: '追加... サブメニュー',
            addToX: '[0]に追加',
            newLoreAsText: '新しいロアーエントリーのテキスト',
            newLoreAsKey: '新しいロアーエントリーのキー',
            generateLore: 'ロアーを生成...',
            generateLoreSubmenu: 'ロアーを生成... サブメニュー',
            tokenize: 'トークン化',
            tokenizeSelection: '選択範囲をトークン化',
            screenshot: 'スクリーンショット',
            screenshotSelection: '選択範囲をスクリーンショット',
            speakTTS: 'TTSで読み上げ',
            browserContextMenuHint: 'ブラウザーのコンテキストメニューを開くにはCtrl+右クリック',
            browserContextMenuHintAlt:
                'ヒント: 通常のブラウザーのコンテキストメニューを開くには、Ctrl+右クリックします。',
            editorContextMenu: 'エディターコンテキストメニュー',
            generateXLore: '[0]のロアーを生成',
            downloadSpokenWithTTS: 'TTS読み上げをダウンロード',
            speakWithSubmenu: '読み上げ... サブメニュー',
            speakWithX: '[0]で読み上げ',
            downloadSpokenWithX: '[0]での読み上げをダウンロード',
        },
        localStorageUnavailable: 'ローカル保存は現在使用できず無効化されています。',
        remoteStorage: 'リモート保存',
        remoteStorageChecked: '現在、物語は暗号化されてサーバー上に保存されています。',
        remoteStorageUnchecked:
            '現在、物語はローカルに保存されています。ローカルに保存されている物語は、しばらく使用されていないとブラウザーによって削除される場合があります。',
        exportStory: '物語をエクスポート',
        toFile: 'ファイルへ',
        asScenario: 'シナリオとして',
        asPlaintext: 'プレーンテキストとして',
        toClipboard: 'クリップボードへ',
        asImage: '画像として',
        storyCopiedToClipboard: '物語をクリップボードにコピーしました。',
    },
    storyStats: {
        viewStoryStats: '物語の統計を見る',
        storyStatistics: '物語の統計情報',
        storyStatisticsDesc: '文章全体を眺めてみましょう。',
        data: 'データ',
        dataBlocks: 'データブロック',
        xAbandoned: '[0]個を破棄',
        user: 'ユーザー',
        ai: 'AI',
        edits: '編集',
        xChained: '[0]個をチェーン',
        lorebookEntries: 'ロアーブックエントリー',
        ephemeralEntries: '一時エントリー',
        writing: '文章',
        characters: '文字',
        xDeleted: '[0]個を削除',
        paragraphs: '段落',
        words: '単語',
        sentences: '文',
        generateAdditionalStats: 'さらに統計を生成',
        structure: '構造',
        currentStep: '現在のステップ',
        furthestStep: '最も遠いステップ',
        deadEnds: '行き詰まり',
        noRetryStreak: 'リトライなしのひと続き',
        longestAbandonedBranch: '最長の破棄された分岐',
        mostRetries: 'リトライの最多回数',
        averageRetries: 'リトライの平均回数',
        mostUsedWords: '使用回数の多い単語',
        experimental: '試験的機能',
        experimentalWarning:
            '警告: 以下のオプションは試験的なものであり、使用によって物語が永久的に破損してしまう可能性があります。先にバックアップを取っておくことを強く推奨します。',
        trimStory: {
            buttonText: '物語のトリミング',
            title: '物語をトリミングしますか？',
            text: {
                '0': '本当に「[0]」の履歴をトリミングしますか？',
                '1': '物語の分岐がすべて削除されます。',
                '2': 'この操作は元に戻せません。',
            },
            confirm: 'トリミングする',
        },
        flattenStory: {
            buttonText: '物語のフラット化',
            title: '物語をフラット化しますか？',
            text: {
                '0': '本当に「[0]」をフラット化しますか？',
                '1': '履歴全体が削除されます。',
                '2': 'この操作は元に戻せません。',
            },
            confirm: 'フラット化する',
        },
        resetToPrompt: {
            buttonText: 'プロンプトにリセット',
            title: '物語をプロンプトにリセットしますか？',
            text: {
                '0': '本当に「[0]」をプロンプトにリセットしますか？',
                '1': 'これまでの入力/出力がすべて削除されます。',
                '2': 'この操作は元に戻せません。',
            },
            confirm: 'リセットする',
        },
        sections: 'セクション',
        noPromptFoundCantReset: 'プロンプトが見つかりません。リセットできません。',
    },
    lorebook: {
        quickAccessPlaceholder: 'エントリーを検索',
        openLorebook: 'ロアーブックを開く',
        confirmDeletion: '再度押すと削除を実行します',
        embedLorebookInPNG: 'ロアーブックをPNGに埋め込む',
        entry: 'エントリー',
        entries: 'エントリー',
        category: 'カテゴリー',
        categories: 'カテゴリー',
        xEntry: '[0]個のエントリー',
        xEntries: '[0]個のエントリー',
        xCategory: '[0]個のカテゴリー',
        xCategories: '[0]個のカテゴリー',
        xSelected: '[0]個のエントリーを選択 ',
        areYouSureYouWantToDeleteXAndY: '本当に[0]と[1]を削除しますか？',
        areYouSureYouWantToDeleteX: '本当に[0]を削除しますか？',
        cannotBeReversed: 'この操作は元に戻せません。',
        deleteThem: '削除する',
        deleteIt: '削除する',
        deleteSelected: '選択した項目を削除しますか？',
        exportSelected: '選択した項目をエクスポート',
        exportSelectedToPng: '選択した項目をPNGにエクスポート',
        newLorebookEntry: '新しいロアーブックエントリー',
        newLorebookCategory: '新しいカテゴリー',
        searchPlaceholder: 'ロアーブックエントリーを検索',
        startMultiselectOperation: '複数選択操作を始める',
        openSortSettings: 'ソート設定を開く',
        changeDisplayOrder: '表示順を変更',
        selectTheSortOrder: 'ソート順を選択',
        generationCategories: {
            general: '一般',
            person: '人物',
            place: '場所',
            thing: 'もの',
            life: '生物',
            faction: 'ファクション',
            role: '役割',
            concept: 'コンセプト',
            history: '歴史',
        },
        introWelcome: {
            '0': 'ようこそ',
            '1': 'ロアーブックへ！',
        },
        introDesc: {
            '0': 'ロアーブックを活用して、物語の世界や出来事、場所、登場人物、環境などの詳細を充実させましょう。設定項目がたくさんありますが、まずは[エントリー]タブだけ設定すれば十分です。',
            '1': '主題に関する情報を[エントリーテキスト]欄に入力し、そのエントリーをAIに表示するためのアクティベーションキーを指定してください。',
        },
        introGetStarted: '[+ エントリー]ボタンをクリックして開始できます。',
        introCreateAnEntry: 'エントリーを作成',
        displayName: '表示名',
        enableLorebookCategory: 'ロアーブックカテゴリーを有効にする',
        defaultPlacementSettings: 'デフォルト配置設定',
        defaultPlacementReset: {
            confirm: 'リセットする',
            text: {
                '0': '本当にエントリーの挿入設定をデフォルトにリセットしますか？',
                '1': 'この操作は元に戻せません。',
            },
            title: '設定をリセットしますか？',
            buttonText: 'デフォルトにリセット',
        },
        defaultEntrySettings: 'デフォルトエントリー設定',
        defaultEntrySettingsChecked:
            'このカテゴリーで作成される新しいロアーブックエントリーには、以下の設定を適用します。',
        defaultEntrySettingsUnchecked:
            'このカテゴリーで作成される新しいロアーブックエントリーは、デフォルトのロアーブックエントリー設定を使用します。',
        updateAllEntries: {
            text: {
                '0': '本当に「[0]」内の各エントリーのすべての設定を更新しますか？',
                '1': 'この操作は元に戻せません。',
            },
            title: 'すべてのエントリーを更新しますか？',
            buttonText: 'すべてのエントリーを更新',
        },
        lorebookCategoryPhraseBias: 'ロアーブックカテゴリーのフレーズバイアス',
        subcontextSettings: 'サブコンテキスト設定',
        createSubcontext: 'サブコンテキストを作成する',
        createSubcontextChecked:
            'このカテゴリーのロアーブックエントリーは、サブコンテキストに分類された後、以下の設定を使用してコンテキストに挿入されます。',
        createSubcontextUnchecked:
            'このカテゴリーのロアーブックエントリーは、通常どおりにコンテキストに挿入されます。',
        tabNames: {
            '': '不明',
            'tab-category-defaults': 'デフォルト',
            'tab-category-subcontext': 'サブコンテキスト',
            'tab-category-bias': 'フレーズバイアス',
            'tab-entry': 'エントリー',
            'tab-context': 'コンテキスト',
            'tab-bias': 'フレーズバイアス',
        },
        replaceDockedTab: '結合タブを入れ替える',
        dockActiveTab: 'アクティブなタブを横に結合',
        docked: '(結合)',
        undock: '結合を解除',
        searchRange: '検索範囲',
        searchRangeTooltip: '指定した文字数を対象として、物語内でキーを検索します。最大値は50000です。',
        keyRelativeInsertion: 'キー相対挿入',
        keyRelativeInsertionTooltip:
            'ロアーブックエントリーは、コンテキスト内で見つかった最後のキーに対して挿入されます。\nプラスの挿入位置を指定すると、エントリーはキーの後に挿入されます。マイナスの挿入位置を指定すると、エントリーはキーの前に挿入されます。\nコンテキスト内でキーが見つかるとは限らず、その場合エントリーは挿入されません。\nこの設定が有効になっているエントリーは、通常、挿入順序が物語のコンテキスト(デフォルトは0)より低く、十分な予約トークンを持っているべきです。\n相対挿入されるロアーブックエントリーが多いと、コンテキストの作成が遅くなる場合があります。',
        cascadingActivation: 'カスケードアクティベーション',
        cascadingActivationTooltip:
            '有効にすると、ロアーブックエントリーは物語以外のコンテキストエントリー内でキーを検索します。\n物語以外のコンテキストエントリーについては、検索範囲は考慮されません。アクティベーションを起こしたエントリーのテキストが、そのコンテキスト内で終わるとは限りません。',
        alwaysOn: '常にオン',
        alwaysOnTooltip: 'キーが検出されたかに関わらず、ロアーブックエントリーは常にアクティベートされます。',
        hiddenEntry: '非表示のエントリー',
        unnamedEntry: '無名のエントリー',
        hiddenTag: '非表示',
        alwaysOnTag: '常にオン',
        entryHasNoKeys: 'エントリーにキーがありません。',
        deleteLorebookEntryX: 'ロアーブックエントリー「[0]」を削除',
        duplicateEntry: 'エントリーを複製',
        toggleSelectionOfEntryX: 'エントリー「[0]」のトグル選択',
        unnamedCategory: '無名のカテゴリー',
        addEntryToCategory: 'エントリーをカテゴリーに追加',
        minimizeCategory: 'カテゴリーを最小化',
        expandCategory: 'カテゴリーを拡大',
        deleteCategory: {
            confirm: '削除する',
            text: {
                '0': '本当にカテゴリー「[0]」を削除しますか？',
                '1': 'この操作は元に戻せません。',
            },
            title: 'カテゴリーを削除しますか？',
        },
        deleteContainingEntries: '中に含まれるエントリーも削除する',
        deleteContainingEntriesChecked:
            'カテゴリーを削除すると、その中に含まれるエントリーもすべて削除されます。',
        deleteContainingEntriesUnchecked:
            'カテゴリーを削除すると、エントリーはすべてカテゴリーの外に移動されます。',
        toggleSelectionOfCategoryX: 'カテゴリー「[0]」のトグル選択',
        lorebookEntryPhraseBias: 'ロアーブックエントリーのフレーズバイアス',
        hiddenEntryDesc: {
            '0': 'このロアーブックエントリーは非表示です。',
            '1': '下のボタンをクリックすると表示できます。',
        },
        revealEntry: 'エントリーを表示',
        placementSettings: '配置設定',
        noCategory: 'カテゴリーなし',
        activationKeys: 'アクティベーションキー',
        activationKeysTooltip:
            '最近使用した物語の中でキーが見つかると、ロアーブックエントリーがアクティベートされ、そのテキストがコンテキストに配置されます。キーは大文字と小文字を区別しません。\n最初と最後が「/」のキーは正規表現と判断されます。これらの正規表現キーは大文字と小文字を区別し、i、s、m、uのフラグに対応しています。',
        activationKeysSub: '最近使用した物語の中でキーが見つかると、エントリーをアクティベートします。',
        activationKeyPlaceholder: 'ここにキーを入力してEnterを押すと保存されます',
        noKeysSet: 'キーが設定されていません。',
        keys: 'キー',
        pressEnterToSaveEdit: 'Enterを押すと編集が保存されます',
        selectAKeyToEditIt: 'キーを選択すると編集できます',
        selectALorebookCategory: 'ロアーブックカテゴリーを選択',
        contextOverloaded: 'コンテキストが超過しています: [0]文字を除外しました。',
        entryText: 'エントリーテキスト',
        entryTextSub: 'キーがアクティベートされるとき、以下のテキストが参照されます。',
        generator: 'ジェネレーター',
        generatorTooltip:
            'AIに空欄を埋めてもらいましょう！エントリーのタイプをリストから選択し、生成したいものを入力して[生成]を押します。物語と同様に、ロアーエントリーのテキストをAIに追記させることもできます。',
        changeSettings: '設定を変更',
        generationType: '生成タイプ',
        generationTypeTooltip:
            '特定のタイプのエントリーを生成するように、ジェネレーターに影響を与えます。ドロップダウンを開いた状態で入力しEnterを押すと、カスタムタイプを設定できます。',
        selectAGenerationType: '生成タイプを選択',
        inputText: '入力テキスト',
        inputTextTooltip:
            '生成してほしいものをここに入力します。「Geometry Incorporated」などの固有名詞や「an enthusiastic merchant」などの短い記述が最適です。\n\nエントリーを詳述するために、タグを括弧付きで加えることもできます。例: Stalagmal (prison, space)',
        addContext: 'コンテキストを追加(拡張機能)',
        addContextTooltip:
            'エントリー生成時に情報を使用できるように、メモリーや作者のメモ、最新の物語テキスト(2500文字まで)、コンテキスト内の他のロアーブックエントリーを含めます。',
        enterLorebookKeysToSearch: '検索するロアーブックキーを入力',
        lorebookKeySearchTooltip:
            'ここに入力したテキストのロアーブックアクティベーションキーが検索され、一致したロアーがコンテキストに配置されます。',
        generationHistory: '生成履歴',
        generationHistoryDesc: '最新の出力50件を表示しています。ページを更新するとクリアされます。',
        historyEmpty: '履歴は空です',
        entryTextPlaceholder: 'エントリーに関する情報をここに入力します。',
    },
    contextSettings: {
        prefix: '接頭辞',
        prefixTooltip: 'トリミング後かつコンテキストへの挿入前にエントリーの先頭に付けるテキスト。',
        suffix: '接尾辞',
        suffixTooltip: 'トリミング後かつコンテキストへの挿入前にエントリーの語尾に付けるテキスト。',
        tokenBudget: 'トークンバジェット',
        tokenBudgetTooltip:
            'エントリーが使用できるコンテキストの最大トークン数。\n0以上1以下の小数を指定した場合、最大コンテキストサイズ(最大トークン数 - 出力の長さ)に対するパーセント値と解釈されます。',
        reservedTokens: '予約トークン',
        reservedTokensTooltip:
            'エントリーが自身のために予約できるコンテキストのトークン数。すべての予約は、エントリーがコンテキストに配置される前に行われます。エントリーが実際に包含する以上のトークン数を予約することはありません。\n0以上1以下の小数を指定した場合、最大コンテキストサイズ(最大トークン数 - 出力の長さ)に対するパーセント値と解釈されます。',
        insertionOrder: '挿入順序',
        insertionOrderTooltip:
            'コンテキストが構築される前に、エントリーは挿入順序に従って並べられます。挿入順序が高いエントリーから先にトークンを予約し、コンテキストに挿入されます。\n同じ挿入順序のエントリーが2つある場合、どちらが先に挿入されるか保証はありません。',
        insertionPosition: '挿入位置',
        insertionPositionTooltip:
            'エントリーがコンテキストに挿入される場所。0はコンテキストの1番上、1はその1ユニット下、2は2ユニット下などとなります。\nマイナスの数値はコンテキストの下からカウントし、-1が1番下、-2はその1ユニット上、-3は2ユニット上などとなります。',
        trimDirection: 'トリミング方向',
        trimDirectionTooltip:
            'エントリー全体がコンテキストに入りきらないときにエントリーをどの方向からトリミングするかを指定します。\n[トリミングしない]に設定すると、テキスト全体がコンテキストに入りきるときのみエントリーを挿入します。',
        trimDirectionLabels: {
            top: '上',
            bottom: '下',
            doNotTrim: 'トリミングしない',
        },
        maximumTrimType: '最大トリミングタイプ',
        maximumTrimTypeTooltip:
            '改行>文>トークンの順で、どの範囲までエントリーをトリミングしてよいかを指定します。',
        maximumTrimTypeLabels: {
            newline: '改行',
            sentence: '文',
            token: 'トークン',
        },
        insertionType: '挿入タイプ',
        insertionTypeTooltip:
            'エントリーを挿入するときに、どのユニットでコンテキストを分けるかを指定します。',
        insertionTypeLabels: {
            newline: '改行',
            sentence: '文',
            token: 'トークン',
        },
    },
    phraseBias: {
        phraseBias: 'フレーズバイアス',
        phraseBiasDesc: 'AIが特定の単語やフレーズを生成する可能性に重みを付けます。',
        biasTooltip:
            '単語やフレーズが生成される可能性を増減させるためのバイアスを設定します。テキストそのものを入力する場合は{波括弧}で囲みます。(トークナイザー固有の)トークンIDを入力する場合は[角括弧]で囲みます。',
        selectAPhraseGroup: 'フレーズグループを選択',
        groupXBiasX: 'グループ: [0], バイアス: [1]',
        biasX: 'バイアス [0]',
        empty: '空',
        noGroupSelected: 'グループが選択されていません',
        addPhraseGroup: 'フレーズグループを追加',
        deletePhraseGroup: 'フレーズグループを削除',
        instruction: '下の欄に入力してEnterを押すと保存されます。',
        placeholder: 'バイアスをかけるテキストを入力',
        addPhrase: 'フレーズを追加',
        less: '少なく',
        more: '多く',
        zeroWarning: 'バイアスを0にすると何も効果はありません。',
        highBiasWarning: 'バイアスを[0]より大きくすると効果が非常に強くなります。 推奨値は-[0]から[0]です。',
        lowBiasdWarning: 'バイアスを-[0]より小さくすると効果が非常に強くなります。推奨値は-[0]から[0]です。',
        phrases: 'フレーズ',
        clickToEdit: 'クリックして編集',
        enableTooltip: 'このフレーズグループを有効または無効にします。',
        whenEntryInactive: 'エントリーが非アクティブな場合',
        whenAllEntriesInactive: 'すべてのエントリーが非アクティブな場合',
        whenEntryInactiveTooltip:
            '有効にすると、このグループはエントリーがアクティブではなく非アクティブなときに適用されます。',
        whenAllEntriesInactiveTooltip:
            '有効にすると、このグループはこのカテゴリーのエントリーがいずれもアクティブでないときのみに適用されます。',
        ensureCompletion: '開始したら必ず完了させる',
        ensureCompletionTooltip:
            '有効にすると、フレーズの最初のトークンが生成されると必ず後続のトークンも生成されます。',
        unbiasWhenGenerated: '生成したらバイアスを外す',
        unbiasWhenGeneratedTooltip:
            '有効にすると、フレーズが生成された後、残りの生成についてはバイアスを取り外します。\n生成をやめるのではなく、生成に加えられるバイアスを外すだけです。',
        bias: 'バイアス',
    },
    bannedTokens: {
        bannedtokens: '使用禁止トークン',
        bannedTokensDesc: '特定のトークンシーケンスが生成されないようにします。',
        bannedTokensTooltip:
            'トークンのシーケンスが生成されるのを禁止します。類似のものも自動的に禁止するには、規則的に入力してください。テキストそのものを入力する場合は{波括弧}で囲みます。(トークナイザー固有の)トークンIDを入力する場合は[角括弧]で囲みます。',
        banBracketGeneration: '括弧の生成を禁止する',
        banBracketGenerationChecked: '括弧を含むトークンは生成されません。',
        banBracketGenerationUnchecked: '括弧を含むトークンも生成できます。',
        selectABanGroup: '禁止グループを選択',
        groupX: 'グループ: [0]',
        inputPlaceholder: '禁止するテキストを入力',
        addBannedSequence: '使用禁止シーケンスを追加',
        sequences: 'シーケンス',
        clickToEdit: 'クリックして編集',
        enableTooltip: 'この使用禁止グループを有効または無効にします。',
    },
    larry: {
        wantSomeHelp: 'ヘルプが必要ですか？',
        yes: 'はい',
        no: 'いいえ',
    },
    infobar: {
        story: {
            title: '物語',
            saveStoryModuleToAccount: '物語モジュールをアカウントに保存',
            selectAnAiModule: 'AIモジュールを選択',
            selectAnAiModel: 'AIモデルを選択',
            storyMode: 'ストーリーモード',
            taModuleRecommended: 'テキストアドベンチャーUIでは[0]モジュールの使用を推奨します。',
            changeDefault: 'デフォルトを変更',
            aiModel: 'AIモデル',
            aiModule: 'AIモジュール',
            aiModulesSub: 'AIが生成するテキストのスタイルを変更します。',
            allModules: 'すべてのモジュール',
            instructHelp: 'インストラクションとは？詳細はここをクリックしてください。',
            instructHelpContent: {
                '0': 'インストラクションモジュールの使い方',
                '1': 'これは現在テスト中の試験的なインストラクションモジュールのため、そのほぼすべてにおいて変更の可能性があります。追加のツールや機能が今後追加される予定です。',
                '2': '本来の形式では、指示は次のような形式になります:',
                '3': '{ Write a description of the sights and sounds of public transit.(公共交通機関の光景や音を記述して) }',
                '4': 'ただし、エディターV2の物語では、より簡単に、行の最初に「{」を入力するだけで構いません。これで、その段落は指示として明示されます。括弧を閉じる必要もなく、括弧は自動的に処理されます。',
                '5': 'モジュールなしでも指示できる？',
                '6': 'インストラクションモジュールを選択しなくても、指示を行うことは可能です！',
                '7': '命令を使うと生成が一時的に(約1000文字分)インストラクションモジュールに切り替わるので、わざわざモジュールを切り替えることなく、必要に応じて指示を使用することができます。',
            },
            configPreset: '設定プリセット',
            configPresetSub: 'AIモデルの設定を変更します。',
            editPreset: 'プリセットを編集',
            memorySub: 'AIはここに入力された情報をよく記憶します。',
            authorsNoteSub: 'ここに入力した情報は、AIの出力に強く影響します。',
            lorebookQuickAccess: 'ロアーブックへのクイックアクセス',
            storyOptions: '物語オプション',
            deleteStory: '物語を削除',
            deleteStoryHint: '(元に戻せません)',
        },
        advanced: {
            title: '詳細',
            duplicateAndStart: '複製してシナリオとして開始',
            duplicateAndStartSub: '現在の物語をプレースホルダー付きのシナリオとしてインポートします',
            minOutputLength: '出力の最小長',
            minOutputLengthTooltip:
                '生成出力の最小長をトークンで指定します(平均4 - 5文字)。\n停止シーケンスが少なくとも1つ設定されているときにのみ効果があります。',
            stopSequences: '停止シーケンス',
            stopSequencesTooltip:
                '指定したトークンシーケンスに到達すると生成を中断します。注: 出力の最小長([0])未満の場合は、生成は中断されません。(トークナイザー固有の)トークンIDを入力する場合は[角括弧]で囲みます。',
            stopSequenceInputPlaceholder: 'ここに入力してEnterを押すと追加できます',
        },
        generationSettings: {
            title: '生成設定',
            cantDeleteNonUserPresetX: '非ユーザープリセット「[0]」は削除できません',
            presetAreadyImported:
                'AIモデル「[1]」のプリセット「[0]」はすでに「[2]」としてインポートされています。',
            importedPreset: 'AIモデル「[1]」のインポート済みプリセット「[0]」',
            importedGenerationSettingsAsPreset: 'プリセットとしてインポートされた生成設定',
            cfgWarning: {
                '0': 'このプリセットは、現在サポートされていないCFGを使用します。',
                '1': 'パフォーマンスに影響が出る可能性があるため、別のプリセットを推奨します。',
            },
            configPresetSub: '生成設定をリストから選択してください。',
            presetInputPlaceholder: '名前をここに入力してEnterを押します',
            createNewPreset: '新しいプリセットを作成',
            settingsSavedToStory: '設定を物語に保存しました。',
            saveToNewPresetQ: '新しいプリセットに保存しますか？',
            updateActivePreset: 'アクティブなプリセットを更新しますか？',
            otherOptions: 'その他のオプション',
            resetChanges: '変更をリセット',
            saveToNewPreset: '新しいプリセットに保存',
            generationOptions: '生成オプション',
            advancedOptions: '詳細オプション',
            randomness: 'ランダム性',
            randomnessTooltip: '値が高いほど、ランダムな出力になります！',
            outputLength: '出力の長さ',
            outputLengthTooltip: '生成される応答の長さを増やします。',
            repetitionPenalty: '反復ペナルティ',
            repetitionPenaltyTooltip: '値が高いほど、出力の繰り返しが少なくなります。',
            topK: 'Top K',
            topKTooltip: 'ここで指定した数だけトークンを保持し、残りを削除します。',
            xOff: '[0] (オフ)',
            off: '(オフ)',
            nucleus: 'Nucleus',
            nucleusTooltip:
                '合計がここで指定した値に達するまで最大のトークン確率を加え、その後に残りのトークンを削除します。値が高いほど、多くのトークンを保持します。',
            tailFree: 'Tail-Free',
            tailFreeTooltip:
                '複雑な計算を用いて、低確率のトークンを切り捨てます。値が高いほど、多くのトークンを保持します。',
            topA: 'Top A',
            topATooltip: '(トップ確率)^2 * Aより確率が小さいトークンを削除します。',
            typical: 'Typical',
            typicalTooltip:
                '低確率のトークンを削除し、高確率のトークンを時々削除し、その中間を残します。値が高いほど、多くのトークンを保持します。',
            phraseRepetitionPenalty: 'フレーズ反復ペナルティ',
            phraseRepetitionPenaltyTooltip:
                'フレーズ反復ペナルティはトークンシーケンスにペナルティを課し、前に出力したテキストを繰り返し生成する可能性を下げます。他の反復ペナルティ設定と一緒に使用できますが、それらの設定の影響は受けません。',
            selectAPhraseRepetitionPenaltyStrength: 'フレーズ反復ペナルティの強度を選択',
            praseRepetitionPenaltyOptions: {
                off: 'オフ',
                very_light: 'とても軽い',
                light: '軽い',
                medium: '中程度',
                aggressive: '強い',
                very_aggressive: 'とても強い',
            },
            useDefaultWhitelist: 'デフォルトのホワイトリストを使用',
            useDefaultWhitelistChecked: 'デフォルトのトークン一式を反復ペナルティの対象外とします。',
            useDefaultWhitelistUnchecked: '反復ペナルティはすべてのトークンに適用されます。',
            range: '範囲',
            rangeTooltip:
                '最後に生成されたトークンから何個分のトークンを対象として、次の出力時に反復を判断するかを指定します。',
            dynamicRange: 'ダイナミックレンジ',
            dynamicRangeChecked: '反復ペナルティの範囲は動的で、「物語」テキストのみに適用されます。',
            dynamicRangeUnchecked: '反復ペナルティの範囲は一定数のトークンになります。',
            slope: '勾配',
            slopeTooltip:
                '最後のトークンからのペナルティの厳しさの増減に影響します。\n値が高いほど、最後のトークンにより厳しくペナルティを課し、前の方のトークンには甘くなります。値が低いほど、すべてのトークンを通して確率の低下は緩やかになります。',
            presence: '有無',
            presenceTooltip: '反復ペナルティ範囲に現れるトークンの生成に対して静的なペナルティを適用します。',
            frequency: '頻度',
            frequencyTooltip:
                'トークンの生成に対して、そのトークンが反復ペナルティ範囲に現れる回数に応じてペナルティを適用します。',
            cfgScale: 'CFGスケール',
            cfgScaleDescription: 'この設定を有効にすると、応答を生成するのにかかる時間が倍になります。',
            showOpposingPrompt: '逆プロンプトを表示',
            hideOpposingPrompt: '逆プロンプトを非表示',
            opposingPromptHelp: {
                '0': '逆プロンプトを設定する前に[0]を読むことを推奨します。',
                '1': 'ドキュメントページ',
            },
            opposingPrompt: '逆プロンプト',
            opposingPromptPlaceholder:
                '逆プロンプト: 出力に含めたくないものをAIに生成させるテキストをここに加えます。',
            topG: 'Top G',
            topGTooltip: 'Top Kと機能は似ていますが、同等の確率をもつトークンのグループに作用します。',
            mirostatTau: 'Mirostat Tau',
            mirostatTauTooltip:
                'Mirostatは、テキストをTauで設定した一定の複雑さで維持しようとします。他の制限的なサンプリング設定とはうまく連動しない場合があります。',
            mirostatLR: 'Mirostat LR',
            mirostatLRTooltip:
                'Mirostatは、テキストをTauで設定した一定の複雑さで維持しようとします。他の制限的なサンプリング設定とはうまく連動しない場合があります。',
            linear: 'Linear',
            linearTooltip: '値を高くすると、出力のランダム性が少なくなります。',
            quad: 'Quad',
            quadTooltip: '値を高くすると、低確率がさらに低くなります。',
            conf: 'Conf',
            confTooltip: '値を高くすると、ランダムな出力のランダム性が少なくなります。',
            minP: 'Min P',
            minPTooltip: '(トップ確率) * Pより確率が小さいトークンを削除します。',
            experimentationNote:
                'これらの設定を試してみることを奨励しますが、その効果は必ずしもはっきり分かるものではないことにご注意ください。',
            experimentationNoteLinkText: 'これらの設定については、こちらのガイドで説明しています。',
            sampling: 'サンプリング',
            changeSamplers: 'サンプラーを変更',
            xHidden: '[0]個が非表示',
            alternateRepetitionPenalty: 'その他の反復ペナルティ',
            alternateRepetitionPenaltyWarning:
                'これらのオプションは生成に強く影響するため、高い値の設定は推奨しません。',
            disabled: '(無効)',
            orderNames: {
                temperature: 'Temperature(ランダム性)',
                top_k: 'Top Kサンプリング',
                top_p: 'Nucleusサンプリング',
                tfs: 'Tail Freeサンプリング',
                top_a: 'Top Aサンプリング',
                typical_p: 'Typicalサンプリング',
                cfg: 'CFG',
                top_g: 'Top Gサンプリング',
                mirostat: 'Mirostat',
                math1: 'Unified',
                min_p: 'Min P',
            },
            samplers: 'サンプラー',
            samplersDesc: {
                '0': 'ここでサンプラーの順序を変更できます。',
                '1': '無効になっているサンプラーはサイドバーに表示されません。',
            },
        },
    },
    contextViewer: {
        context: 'コンテキスト',
        contextDesc: 'AIに送る内容を確認できます。',
        lastContext: '前回のコンテキスト',
        currentContext: '現在のコンテキスト',
        editContextSettings: 'コンテキスト設定を編集',
        editEphemeralContext: '一時コンテキストを編集',
        advancedContextSettings: '詳細コンテキスト設定',
        applyPresetConfig: 'プリセット設定を適用(レガシー)',
        resetToDefaults: 'デフォルトにリセット',
        memoryContextSettings: 'メモリーのコンテキスト設定',
        authorsNoteContextSettings: '作者のメモのコンテキスト設定',
        defaultLorebookSettings: 'デフォルトロアーブック設定',
        defaultLorebookSettingsTooltip: '新しく作成されるロアーブックエントリーはこの設定を使用します。',
        storyContextSettings: '物語のコンテキスト設定',
        buildingContext: 'コンテキストを構築中...',
        maxTokens: '最大トークン数',
        outputLength: '出力の長さ',
        aiModule: 'AIモジュール',
        continueToSentenceEndAllowance: '文末のゆとり',
        structuredOuputDidNotMatchTextOutput: '構築された出力がテキストの出力と一致しませんでした。',
        xTokensFilledOutOfAMaximumOfY: '最大数[1]のうち[0]トークンを使用済み',
        usingPrefixOverride: '接頭辞のオーバーライドを使用',
        previousStage: '前のステージ',
        nextStage: '次のステージ',
        stageInfo: 'ステージ [0]/[1], 予約: [2], 残り: [3]',
        justX: 'たった今: [0]',
        nextX: '次: [0]',
        fromTheTop: '上から',
        fromTheBottom: '下から',
        belowTheLastFoundKey: '最後に見つかったキーの下の',
        aboveTheLastFoundKey: '最後に見つかったキーの上の',
        shuntedXCharacters: '(別エントリー内への挿入を防ぐために[0]文字を移動させました)',
        insertedX: '[3][1][2]の場所に[0]を挿入しました[4]',
        errorCreatingStructuredOutput:
            '構築された出力[0]を場所[1]で作成中にエラーが発生しました。許容範囲 0-[2]',
        removedLoreActivatingComments: 'ロアーをアクティベートするコメントを削除しました。',
        addedNewlineToEndOfContext: 'コンテキストの最後に改行を追加しました。',
        croppedFinalToken: '最後のトークンを切り取りました。',
        removedEndOfText:
            'コンテキストから<|endoftext|>を削除しました。このモデルではこのトークンを使用しないでください。',
        hidden: '(非表示)',
        cHidden: '(非表示)',
        examine: '(検査)',
        showNonActivatedEntries: 'アクティベートされていないエントリーを表示する',
        subcontextX: 'サブコンテキスト: [0]',
        stage: 'ステージ',
        order: '順序',
        identifier: '識別子',
        identifierTooltip: 'コンテキストエントリーの識別子。ロアーブックエントリーには表示名を使用します。',
        inclusion: '包含',
        inclusionTooltip:
            'エントリーがコンテキストに含まれていたかどうかを示しています。部分的に含まれていたエントリーはトリミングされたエントリーです。',
        reason: '理由',
        reasonTooltip: 'エントリーがコンテキストに含まれていた、もしくは除外された理由。',
        key: 'キー',
        keyTooltip:
            'キーによってアクティベートされたロアーブックエントリーだったためにエントリーが含まれていた場合、そのキーがここに表示されます。',
        reserved: '予約',
        reservedTooltip: 'エントリーが予約したトークンの数。',
        tokens: 'トークン',
        tokensTooltip:
            'エントリーが使用したトークンの数。\nこの列の合計は、結果としてのコンテキストの長さとは異なる場合があります。',
        trimType: 'トリミングタイプ',
        trimTypeTooltip:
            'コンテキストに入るようにエントリーがどのようにトリミングされたかを示しています。\nトリミングする際の優先順位は、トリミングなし > 改行 > 文 > トークンの順となります。',
        includedPhraseBiasGroups: '含まれるフレーズバイアスグループ',
        numberOfIncludedPhraseBiasGroups: '含まれるフレーズバイアスグループの数',
        identifiers: {
            memory: 'メモリー',
            authorsNote: '作者のメモ',
            story: '物語',
            unknown: '不明のデフォルトコンテキスト',
        },
        states: {
            included: '含まれる',
            'partially included': '部分的に含まれる',
            'not included': '含まれない',
        },
        reasons: {
            default: 'デフォルト',
            'no space': 'スペースなし',
            disabled: '無効',
            'no key': 'キーなし',
            'key activated': 'キーをアクティベート',
            'key in:': '中にキーあり:',
            'activation forced': 'アクティベーションを強制実行',
            'no text': 'テキストなし',
            'no key in context': 'コンテキストにキーなし',
            'ephemeral active': '一時的にアクティブ',
            'ephemeral inactive': '一時的に非アクティブ',
            'no active entries': 'アクティブなエントリーなし',
        },
        trimMethods: {
            'no trim': 'トリミングなし',
            token: 'トークン',
            newline: '改行',
            sentence: '文',
        },
    },
    ephemeralContext: {
        ephemeralContext: '一時コンテキスト',
        desc: {
            '0': '一時コンテキストエントリーを使用すると、物語の現在の「ステップ」を基にコンテキストを挿入できます。{ステップ+遅延~期間,位置:テキスト} の形式で入力でき、「テキスト」以外は数値で指定します。位置とテキストのみが必須です。',
            '1': '例: \n一時コンテキストエントリー {+3~10,-2:Example} を指定すると、「Example」というテキストをコンテキストの下から1改行目に、3ステップの遅延の後、10ステップ配置します。',
        },
        currentStoryStepX: '現在の物語ステップ: [0]',
        addEphemeralContextEntry: '一時コンテキストエントリーを追加',
    },
    moduleBrowser: {
        searchPlaceholder: 'モジュールを検索',
        imported: 'インポート',
        importedSub: 'ファイルまたはシナリオからインポートされたモジュール。',
        specials: 'スペシャル',
        specialsSub: '付加機能付きの特殊モジュール',
        general: '一般',
        generalSub: '多くのシチュエーションに適合する一般モジュール。',
        style: 'スタイル',
        styleSub: '好きな作家をお手本にしましょう。',
        theme: 'テーマ',
        themeSub: '特定のテーマに沿って書いてみましょう。',
        inspiration: 'インスピレーション',
        inspirationSub: '有名な作品からインスピレーションを得ましょう。',
        noModulesFound: '現在の検索条件に一致するモジュールはありません。',
    },
    modules: {
        vanilla: {
            label: 'モジュールなし',
            description: 'デフォルトのNovelAIエクスペリエンス。',
        },
        special_openings: {
            label: 'スペシャル: オープニング',
        },
        special_instruct: {
            label: '一般: インストラクション(試験的機能)',
            description: '指示コマンドを使って、AIを特定の方向へ進めます。',
        },
        general_crossgenre: {
            label: '一般: クロスジャンル',
            description: '多くのジャンルに使用できる一般モジュール。',
        },
        theme_textadventure: {
            label: 'スペシャル: テキストアドベンチャー',
            description: '進行方向を変えるのもあなた次第。先へ進んでみますか？',
        },
        special_proseaugmenter: {
            label: '一般: 散文拡張',
            description:
                '長い散文向けの一般モジュール。短文になるなどの品質面の陥りやすいミスにAIが苦戦しているときは、ぜひ散文拡張を試してみてください。',
        },
        style_algernonblackwood: {
            label: 'スタイル: アルジャーノン・ブラックウッド',
            description: '誰もが認める怪奇小説・怪談の巨匠。',
        },
        style_arthurconandoyle: {
            label: 'スタイル: アーサー・コナン・ドイル',
            description:
                '名探偵は、論理と知力、鋭敏な頭脳でどんな事件も解明し真実を明らかにする。「初歩的なことだよ。」',
        },
        style_edgarallanpoe: {
            label: 'スタイル: エドガー・アラン・ポー',
            description:
                '人の心の闇、理性を失った男のゆがんだ望み。文学界屈指の大作家の作品から影響を受ける。',
        },
        style_hplovecraft: {
            label: 'スタイル: H.P. ラヴクラフト',
            description:
                '忘れ去られた世界の片隅で、幽玄な巻きひげを伸ばす生物。その影に潜む未知の恐怖とは...',
        },
        style_shridanlefanu: {
            label: 'スタイル: シェリダン・レ・ファニュ',
        },
        style_janeausten: {
            label: 'スタイル: ジェイン・オースティン',
            description: '摂政時代の知性、ロマンス、社会政治の世界を味わう。',
        },
        style_julesverne: {
            label: 'スタイル: ジュール・ヴェルヌ',
        },
        theme_christmas: {
            label: 'テーマ: クリスマス',
            description: 'ランダムなクリスマステーマで、1年で一番うきうきする季節に瞬間移動！',
        },
        unknown: {
            label: '不明なモジュール',
            description: '不明なモジュール',
        },
    },
    storyList: {
        shelfNotFound: '本棚が見つかりません',
        xStories: '[0]話の物語',
        deleteShelfQ: '本棚を削除しますか？',
        deleteShelf: '本棚を削除',
        deleteText: {
            '0': '本当に本棚「[0]」を削除しますか？',
            '1': '中に入っている物語は本棚の外に移動されます。',
        },
        shelfEmpty: 'この本棚は空です。',
        weCantFind: 'お探しのものが見つかりません。',
        libraryEmpty: 'ライブラリーは空のようです。',
        createdX: '作成日 [0]',
        shelvedIn: '本棚:',
    },
}
