import 'reseter.css'

import 'prosemirror-view/style/prosemirror.css'
import 'react-toastify/dist/ReactToastify.css'

import '../styles/globals.css'

import isPropValid from '@emotion/is-prop-valid'
import * as Sentry from '@sentry/nextjs'
import { MotionConfig } from 'framer-motion'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { default as Router } from 'next/router'
import Script from 'next/script'
import nProgress from 'nprogress'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ThemeProvider } from 'styled-components'

import { HotEvent, HotEventSub, subscribeToHotEvent } from '../data/user/hotkeys'
import { getUserSetting, UserSettings } from '../data/user/settings'
import { CommitHash, Environment, PaddleSandbox, PaddleVendorID, SentryDSN } from '../globals/constants'
import { SessionValue, SiteTheme } from '../globals/state'
import { CustomGlobalStyle, GlobalStyle } from '../styles/global'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { BanNotice } from '../components/bannotice'
import { useLocalization } from '../hooks/useLocalization'
import { useAtomValue } from 'jotai'

if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
        loaded: (posthog) => {
            if (Environment === 'debug') posthog.debug() // debug mode in development
        },
        advanced_disable_feature_flags: true,
    })
}

type NextPageWithLayout = NextPage & {
    Layout?: (page: ReactElement) => ReactNode
}
type AppPropsWithErrAndLayout = AppProps & {
    err: any
    Component: NextPageWithLayout
}

Router.events.on('routeChangeStart', (route: string) => route.startsWith('/stories?id=') || nProgress.start())
Router.events.on(
    'routeChangeComplete',
    (route: string) => route.startsWith('/stories?id=') || nProgress.done()
)
Router.events.on('routeChangeError', () => nProgress.done())

Sentry.init({
    dsn: SentryDSN,
    tracesSampleRate: 0.2,
    environment: Environment,
    release: CommitHash,
    dist: CommitHash + '-' + Environment,
    enabled: !!Environment && Environment !== 'debug',
    autoSessionTracking: false,
})

function AppWithState({ Component, pageProps, err }: AppPropsWithErrAndLayout): JSX.Element {
    const localization = useLocalization()
    const siteTheme = useAtomValue(SiteTheme)
    const settings = useAtomValue(SessionValue('settings')) as UserSettings

    const [showBorder, setShowborder] = useState(false)
    const tabBorder = () => {
        setShowborder(true)
        return true
    }
    const checkClick = (e: MouseEvent) => {
        if (e.detail === 0) {
            // keyboard "click" event
        } else {
            // mouse "click" event
            setShowborder(false)
        }
    }
    useEffect(() => {
        subscribeToHotEvent(
            HotEvent.focusForward,
            new HotEventSub('indexTabF', tabBorder, false, false, false)
        )
        subscribeToHotEvent(HotEvent.focusBack, new HotEventSub('indexTabB', tabBorder, false, false, false))
        subscribeToHotEvent(
            HotEvent.preventEvent,
            new HotEventSub('indexPrevent', (e) => {
                e.preventDefault()
                return true
            })
        )
    }, [])
    useEffect(() => {
        document.addEventListener('mousedown', checkClick)
        return () => {
            document.removeEventListener('mousedown', checkClick)
        }
    })

    useEffect(() => {
        if (typeof document === 'undefined') return
        const meta = document.createElement('meta')
        meta.name = 'darkreader'
        meta.content = 'disable'
        const observer = new MutationObserver(() => {
            const metaFake = document.querySelector('meta[content="' + meta.content + '"]')
            if (!metaFake) {
                document.head.append(meta)
            }
            const metaReal = document.querySelector('meta[name="' + meta.name + '"]')
            if (metaReal && (metaReal as HTMLMetaElement).content != meta.content) {
                metaReal.remove()
            }
            for (const style of document.head.querySelectorAll('.darkreader')) {
                style.remove()
            }
        })
        observer.observe(document.head, { attributes: false, childList: true, subtree: false })
        return () => {
            observer.disconnect()
        }
    }, [])

    const ComponentLayout = Component.Layout ?? ((page) => page)
    return (
        <>
            <CustomGlobalStyle global={siteTheme.global ?? ''} />
            <GlobalStyle
                theme={siteTheme}
                fontSize={getUserSetting(settings, 'fontScale')}
                outputFontSize={getUserSetting(settings, 'outputFontScale')}
                paragraphIndent={getUserSetting(settings, 'paragraphIndent')}
                paragraphSpacing={getUserSetting(settings, 'paragraphSpacing')}
                lineSpacing={getUserSetting(settings, 'lineSpacing')}
                editorHighlighting={getUserSetting(settings, 'editorHighlighting')}
                buttonScale={getUserSetting(settings, 'buttonScale')}
                focusBorder={showBorder}
                editorBackground={getUserSetting(settings, 'confetti')}
                localization={localization}
            />
            <ThemeProvider theme={siteTheme}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                {ComponentLayout(<Component {...pageProps} err={err} />)}
                <BanNotice />
                {getUserSetting(settings, 'keyboardDisplacesContent') && (
                    <Head>
                        <meta
                            name="viewport"
                            // eslint-disable-next-line max-len
                            content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover, interactive-widget=resizes-content"
                        />
                    </Head>
                )}
            </ThemeProvider>
        </>
    )
}

function App(props: AppPropsWithErrAndLayout): JSX.Element {
    return (
        <>
            <Head>
                <meta charSet="utf8" />
                <meta
                    name="viewport"
                    // eslint-disable-next-line max-len
                    content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover"
                />
                <meta name="theme-color" content="#000000" />
                <meta
                    name="description"
                    // eslint-disable-next-line max-len
                    content="NovelAI is the #1 AI image generator tool for generating AI anime art and crafting epic stories with our storytelling models. Unleash your creativity, generate anime images and stories, with no restrictions!"
                />
                <meta
                    name="keywords"
                    // eslint-disable-next-line max-len
                    content="novelai, novel ai, nai, ai image, ai art, ai writing, ai storytelling, creative writing ai, text generation, image generation, narrative ai, ai-powered, art generator, ai art creation, openai, stable diffusion, novelai diffusion, anime style art, manga art generator, waifu generator, anime character creator, ai dungeon, openai gpt, gpt-4, machine learning, natural language processing, ai writing assistant, fantasy ai, sci-fi story generator, visual novel creator, ai roleplay, text-to-image, prompt, AI anime art, storytelling, creativity, anime images, no restrictions"
                />
                <meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
                <meta httpEquiv="Pragma" content="no-cache" />
                <meta httpEquiv="Expires" content="0" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

                <meta name="twitter:card" content="summary_large_image" key="twitter:card" />
                <meta name="twitter:site" content="@novelaiofficial" key="twitter:site" />
                <meta name="twitter:creator" content="@novelaiofficial" key="twitter:creator" />
                <meta
                    name="twitter:title"
                    content="NovelAI - AI Anime Image Generator & Storyteller"
                    key="twitter:title"
                />
                <meta
                    name="twitter:description"
                    // eslint-disable-next-line max-len
                    content="NovelAI is the #1 AI image generator tool for generating AI anime art and crafting epic stories with our storytelling models. Unleash your creativity, generate anime images and stories, with no restrictions!"
                    key="twitter:description"
                />
                <meta name="twitter:image" content="https://novelai.net/social.png" key="twitter:image" />

                <meta property="og:type" content="website" key="og:type" />
                <meta property="og:site_name" content="NovelAI" key="og:site_name" />
                <meta
                    property="og:title"
                    content="NovelAI - AI Anime Image Generator & Storyteller"
                    key="og:title"
                />
                <meta property="og:url" content="https://novelai.net" key="og:url" />
                <meta property="og:image" content="https://novelai.net/social.png" key="og:image" />

                <meta name="darkreader" content="disable" />
            </Head>
            <Script
                src="https://cdn.paddle.com/paddle/paddle.js"
                strategy="afterInteractive"
                onLoad={() => {
                    if (PaddleSandbox) {
                        ;(window as any).Paddle.Environment.set('sandbox')
                    }
                    ;(window as any).Paddle.Setup({ vendor: PaddleVendorID, completeDetails: true })
                }}
            />
            <Script
                src="/js/706c61757369626c65.vendor.js"
                data-domain="novelai.net"
                data-api="https://plausible.io/api/event"
                strategy="afterInteractive"
                onLoad={() => {
                    ;(window as any).plausible =
                        (window as any).plausible ||
                        function (...args: any) {
                            ;((window as any).plausible.q = (window as any).plausible.q || []).push(args)
                        }
                }}
            />
            <PostHogProvider client={posthog}>
                <MotionConfig isValidProp={isPropValid}>
                    <DndProvider backend={HTML5Backend}>
                        <AppWithState {...props} />
                    </DndProvider>
                </MotionConfig>
            </PostHogProvider>
        </>
    )
}

export default App
