import { StoryId, StoryMetadata } from '../data/story/storycontainer'
import { User } from '../data/user/user'
import { Dark } from '../styles/themes/dark'
import { Theme } from '../styles/themes/theme'
import { ContextReport } from '../data/ai/context'
import { AIModule, StoryPreset } from '../data/story/storysettings'
import { UpdateNote } from '../data/updates/updatenote'
import { DefaultInputModes } from '../data/story/defaultinputmodes'
import { LogProbs } from '../data/request/remoterequest'
import { EncoderType } from '../tokenizer/enums'
import { StoryMode } from '../data/story/story'
import { DefaultModel } from '../data/request/model'
import { PlatformImageData } from '../compatibility/platformtypes'
import { GlobalUserContext } from './globals'
import { FileInfo } from '../components/controls/fileinput'
import { ImageTool, ImageToolInput } from '../data/image/imagetoolutil'
import { ImageGenResult } from '../data/image/imageutil'
import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { getLocalizationForUser } from '../hooks/useLocalization'

class LastResponseData {
    tokens: number[] = new Array<number>()
    logprobs?: LogProbs[]
    tokenizer: EncoderType = EncoderType.GPT2
}

export const Session = atom<User>(new User('', ''))

export const SessionValue = atomFamily((field: keyof User) =>
    atom((get) => {
        return get(Session)[field]
    })
)

// In recoil had dangerouslyAllowMutability: true, unsure if something similar is needed in jotai
export const LastContextReport = atom<ContextReport>(new ContextReport())

// In recoil had dangerouslyAllowMutability: true, unsure if something similar is needed in jotai
export const CurrentContext = atom<ContextReport>(new ContextReport())

export const LastResponse = atom<LastResponseData>(new LastResponseData())

export const Stories = atom<Array<StoryId>>([])

export const StoryShelves = atom<Array<StoryId>>([])

export const SelectedShelf = atom('')

export class StoryStateValue {
    id: StoryId
    update?: number
    loaded?: boolean
    selected?: boolean
    error?: string

    constructor(id?: StoryId, update?: number, loaded?: boolean, selected?: boolean, error?: string) {
        this.id = id ?? ''
        this.update = update
        this.loaded = loaded
        this.selected = selected
        this.error = error
    }
}

export const StoryStates = atomFamily((id: StoryId) => atom(new StoryStateValue(id)))

const SelectedStoryState = atom<StoryStateValue>(new StoryStateValue(''))

export const StoryUpdate = atomFamily((id: StoryId) => {
    return atom(
        (get) => {
            return get(StoryStates(id))
        },
        (get, set, newState: StoryStateValue) => {
            const oldState = get(StoryStates(newState.id))
            const newValue = new StoryStateValue(
                newState.id ?? oldState.id ?? '',
                (newState.update ?? 0) == (oldState.update ?? 0)
                    ? (newState.update ?? 0) + 1
                    : (newState.update ?? 0) + (oldState.update ?? 0),
                newState.loaded ?? oldState.loaded,
                newState.selected ?? oldState.selected,
                newState.error ?? oldState.error
            )
            set(StoryStates(''), newValue)
            set(StoryStates(newValue.id), newValue)

            if (newValue.selected) {
                const prevSelected = get(SelectedStoryState)
                set(SelectedStoryState, newValue)

                if (prevSelected.id !== newValue.id) {
                    const prevNew = new StoryStateValue(
                        prevSelected.id,
                        prevSelected.update,
                        false,
                        false,
                        ''
                    )
                    set(StoryStates(prevNew.id), prevNew)
                }
            }
        }
    )
})

export const SelectedStory = atom<
    StoryStateValue,
    [StoryStateValue | ((old: StoryStateValue) => StoryStateValue)],
    void
>(
    (get) => get(SelectedStoryState),
    (get, set, selected: StoryStateValue | ((old: StoryStateValue) => StoryStateValue)) => {
        if (selected) {
            const prevSelected = get(SelectedStoryState)

            if (typeof selected === 'function') {
                selected = selected(prevSelected)
            }

            if (
                prevSelected.id === selected.id &&
                prevSelected.loaded === selected.loaded &&
                prevSelected.error === selected.error
            ) {
                return
            }

            const oldState = get(StoryStates(selected.id))
            set(
                StoryUpdate(selected.id),
                new StoryStateValue(
                    selected.id,
                    selected.update ?? oldState.update,
                    selected.loaded ?? oldState.loaded,
                    true,
                    selected.error
                )
            )
        }
    }
)

export const SelectedStoryId = atom((get) => {
    return get(SelectedStoryState).id
})

export const SelectedStoryLoaded = atom((get) => {
    return get(SelectedStoryState).loaded || false
})

export const SelectedStoryModified = atom((get) => {
    const selectedStoryId = get(SelectedStoryState).id
    const story = GlobalUserContext.storyContentCache.get(selectedStoryId)
    const meta = GlobalUserContext.stories.get(selectedStoryId)
    return story?.settingsDirty || meta?.isModified || !!story?.getStoryText()
})

export const SelectedStoryMode = atom((get) => {
    return (
        GlobalUserContext.storyContentCache.get(get(SelectedStoryState).id)?.settings.prefixMode ??
        StoryMode.normal
    )
})

export const SelectedStoryModel = atom((get) => {
    return GlobalUserContext.storyContentCache.get(get(SelectedStoryState).id)?.settings.model ?? DefaultModel
})

export const SelectedStoryModule = atom((get) => {
    return GlobalUserContext.storyContentCache.get(get(SelectedStoryState).id)?.settings.prefix ?? ''
})

export const SelectedStoryError = atom((get) => {
    return get(SelectedStoryState).error ?? ''
})

export const GenerationRequestActive = atom(false)

export const GenerationRequestCancelled = atom(false)

export const GenerationRequestError = atom('')

export const SiteTheme = atom<Theme>(Dark)

export const ModalsOpen = atom(0)

export const SplashModalOpen = atom<boolean | undefined>(void 0)

export const SettingsModalOpen = atom(-1)

export const SaveStatus = atom('')

export const SubscriptionDialogOpen = atom({ open: false, blocked: false })

export const LorebookOpen = atom(false)

export const SelectedLorebookEntry = atom('')

export const LorebookGenerateClipboard = atom({ text: '', group: '' })

export const LorebookGenerateKeysInput = atom('')

export const TokenProbOpen = atom(false)

export const UserPresets = atom<Array<StoryPreset>>([])

export const CustomModules = atom<Array<AIModule>>([])

export const UpdateNotesVisible = atom(false)

export const UpdateNotesUnread = atom(false)

export const UpdateNotes = atom<Array<UpdateNote>>([])

export const SelectedNoteIndex = atom(0)

export const PrefixTrainingOpen = atom(false)

// In recoil had dangerouslyAllowMutability: true, unsure if something similar is needed in jotai
export const InputModes = atom(DefaultInputModes)

// In recoil had dangerouslyAllowMutability: true, unsure if something similar is needed in jotai
export const SelectedInputMode = atom(DefaultInputModes[0])

export const ContextViewerPage = atom(-1)

export const TokenizerOpen = atom(false)

export const TokenizerText = atom('')

export const StorySearch = atom('')

export const StorySort = atom({
    reverse: false,
    by: {
        label: 'Most Recent',
        value: 'recent',
    },
})

export const ScenarioSelected = atom(0)

export const LorebookSearch = atom('')

export const LorebookSort = atom({
    reverse: false,
    by: {
        label: 'Alphabetical',
        value: 'alphabetical',
    },
})

interface UserPromptModalChoice {
    label: string
    text: JSX.Element
    hint?: string
    options: Array<{ text: string | JSX.Element; onClick: () => void; color?: string }>
}
export const UserPromptModal = atom<null | UserPromptModalChoice>(null)

export const TutorialState = atom({
    state: -1,
    next: () => {
        //
    },
    prev: () => {
        //
    },
})

export const MenuBarOpen = atom(false)

export const InfoBarOpen = atom(false)

export const ShowTutorial = atom(false)

export const RemeteSaveFailed = atom('')

export const AppUpdateAvailable = atom(false)

export const ThemePreview = atom(Dark)

export const TrialUsageRemaining = atom(0)

export const TrialUsedModal = atom(false)

export const IPLimitModal = atom(false)

export const LorebookGenerateOpen = atom(false)

export const LorebookGeneratedExpanded = atom(false)

export const InfobarSelectedTab = atom(0)

export interface LorebookTabData {
    entry: string
    category: string
    pinnedEntry: string
    pinnedCategory: string
}

export enum LorebookEntryTabs {
    Entry = 'tab-entry',
    Context = 'tab-context',
    Bias = 'tab-bias',
    None = '',
}

export enum LorebookCategoryTabs {
    Defaults = 'tab-category-defaults',
    Subcontext = 'tab-category-subcontext',
    Bias = 'tab-category-bias',
    None = '',
}

export const LorebookTabs = atom({
    entry: LorebookEntryTabs.Entry,
    category: LorebookCategoryTabs.Defaults,
    pinnedEntry: LorebookEntryTabs.None,
    pinnedCategory: LorebookCategoryTabs.None,
})

export const GiftKeyOpen = atom(false)

export const ScreenshotModalState = atom({
    open: false,
    start: 0,
    end: 0,
})

export const TTSState = atom({
    paused: false,
    stopped: true,
    commentSpeaking: false,
})

export const CommentState = atom({
    text: '',
    generating: false,
    streaming: false,
    hidden: true,
    error: false,
    image: null as null | { id: any; img: PlatformImageData; alt: string },
})

export const TipState = atom({
    tip: -1,
    easterEggTip: -1,
})

export const CheckEditor = atom(0)

export const GenjiNotice = atom(false)

export const RerollSeeds = atom<
    Array<{
        seed: string
        locked?: boolean
    }>
>([
    {
        seed: '',
    },
])

export const UseConvertedImage = atom(true)

export const DuplicateIDStories = atom<Map<string, Array<StoryMetadata>>>(new Map())

export const AddChristmas = atom(false)

export const PaymentDialogPage = atom(0)

export const ContestSubmissionFile = atom<null | FileInfo>(null)

export const ShowContestSubmissionModal = atom(false)

export const ImageToolState = atom(ImageTool.bgRemoval)

export const ImageGenResults = atom<Array<Array<ImageGenResult>>>([])

export const ImageToolInputs = atom<Array<ImageToolInput>>([])

export const SelectedImageToolInput = atom(0)

export type SelectedImageToolResult = {
    image: number
    subimage: number | undefined
    variation: number
}

export const SelectedImageResult = atom<SelectedImageToolResult>({
    image: 0,
    subimage: undefined,
    variation: 0,
})

export const ToolParams = atom<any>({})

export const ImageToolOpen = atom(false)

export const Llama3LicenseModal = atom(false)

export const ConfirmEmail = atom('')

export const Localization = atom((get) => {
    const session = get(Session)
    const loc = getLocalizationForUser(session)
    return loc
})
